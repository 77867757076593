import { createEntityAdapter } from '@reduxjs/toolkit';
import api from '@shared/api';
import createCacheQueryHelper from '@shared/entities/createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import { GeometryDataEndpointParamsT, GeometryDataT } from './geometryData.types';

export const geometryDataCacheAdapter = createEntityAdapter<GeometryDataT>({
    sortComparer: getDefaultSortComparerById<GeometryDataT>(),
});

const geometryDataCacheQueryHelper = createCacheQueryHelper<GeometryDataEndpointParamsT, GeometryDataT, 'GeometryData'>(
    api,
    'GeometryData',
    geometryDataCacheAdapter,
);

export default {
    transformResponse: geometryDataCacheQueryHelper.transformResponse,
    remove: geometryDataCacheQueryHelper.remove,
    removeAll: geometryDataCacheQueryHelper.removeAll,
};
