import { ReactNode } from 'react';
import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { useFieldCropInfoBox } from './FieldCropInfoBox.style';
import { IconLocalisation } from '@soil-capital/ui-kit/icons';
import { cropNamesT } from '@shared/map/types/mapTypes';

export type FieldCropInfoBoxProps = {
    fieldName: string;
    fieldArea: string;
    cropNames?: cropNamesT[];
    mapSnapshot: string | null;
    isInvalid?: boolean;
    icon?: ReactNode;
    snapshotSize?: 'small' | 'medium' | 'large';
    className?: string;
    handleFieldClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
};

export const FieldCropInfoBox = ({
    fieldName,
    fieldArea,
    cropNames,
    mapSnapshot,
    isInvalid,
    icon,
    snapshotSize = 'medium',
    className,
    handleFieldClick,
    disabled,
}: FieldCropInfoBoxProps) => {
    const hasCrops = cropNames && cropNames?.length > 0;
    const { classes } = useFieldCropInfoBox({ hasHandleFieldClick: !!handleFieldClick });

    return (
        <MediaInfoBlock
            disabled={disabled}
            Title={<Typography color={isInvalid ? 'error' : ''}>{fieldName}</Typography>}
            Subtitle={
                <Grid display="flex" flexDirection="column" width="100%">
                    <Grid display="flex" width="100%" justifyContent="flex-start" gap="8px" alignItems="center">
                        <div style={{ maxWidth: '70%', display: hasCrops ? 'block' : 'none' }}>
                            {hasCrops &&
                                cropNames?.map((cropName, index) => (
                                    <MediaInfoBlock.Subtitle key={index} text={cropName.name} />
                                ))}
                        </div>
                        <Typography variant="subcaption" style={{ maxWidth: '20%' }}>
                            {fieldArea}
                        </Typography>
                    </Grid>
                </Grid>
            }
            Media={
                <div
                    className={isInvalid ? classes.invalidFieldSnapshotContainer : classes.snapshotContainer}
                    onClick={handleFieldClick}
                >
                    <MediaInfoBlock.Image src={mapSnapshot} size={snapshotSize} />
                    {handleFieldClick && (
                        <>
                            <div className={isInvalid ? classes.invalidFieldOverlay : classes.overlay} />
                            <IconLocalisation className={isInvalid ? classes.invalidFieldIcon : classes.icon} />
                        </>
                    )}
                </div>
            }
            Icon={icon}
            className={`${className} ${disabled ? classes.disabledCursor : ''}`}
        />
    );
};
