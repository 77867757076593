import { BoxedIcon } from '@soil-capital/ui-kit/BoxedIcon';
import { LinearProgress } from '@soil-capital/ui-kit/LinearProgress';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useNavItemStyle } from './NavItem.style';
import { Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useMatch, useNavigate } from 'react-router-dom';
import { ProgressStatusT } from '@shared/entities/progress/progress.types';
import { Tooltip } from '@soil-capital/ui-kit/components';

export type NavItemPropsT = {
    title: string;
    progress?: number | null;
    subtitle?: string;
    startIcon?: JSX.Element;
    status: ProgressStatusT;
    path: string;
    isVisible: boolean;
    hoverText?: string;
};

export default ({ title, progress = 0, subtitle, startIcon, status, path, isVisible, hoverText }: NavItemPropsT) => {
    const { classes } = useNavItemStyle();
    const theme = useTheme();
    const navigate = useNavigate();

    const active = useMatch(`/:seasonId/${path}/*`);
    const goToPath = () => navigate(path);

    return !isVisible ? null : (
        <>
            <Tooltip text={hoverText}>
                {status === 'done' && (
                    <div
                        data-testid="done-nav-item"
                        className={`${classes.container} ${
                            active ? classes.activeDoneContainer : classes.doneContainer
                        }`}
                        onClick={goToPath}
                    >
                        <div>{startIcon && <BoxedIcon variant="success" icon={startIcon} />}</div>
                        <div className={classes.title}>
                            <Typography
                                color={active ? theme.palette.darkScale[900] : theme.palette.darkScale[50]}
                                variant="caption"
                            >
                                {title}
                            </Typography>
                        </div>
                        {!active && (
                            <div className={classes.endIcon}>
                                <IconArrowRight className={classes.arrowIcon} />
                            </div>
                        )}
                    </div>
                )}
                {status === 'ongoing' && (
                    <div
                        data-testid="ongoing-nav-item"
                        className={`${classes.container} ${active ? classes.activeContainer : classes.containerHover}`}
                        onClick={goToPath}
                    >
                        <div>
                            {startIcon && (
                                <BoxedIcon
                                    style={{
                                        backgroundColor: active
                                            ? theme.palette.darkScale[900]
                                            : theme.palette.primaryScale[800],
                                    }}
                                    icon={startIcon}
                                />
                            )}
                        </div>
                        <div className={classes.title}>
                            <Typography
                                color={active ? theme.palette.darkScale[900] : theme.palette.darkScale[50]}
                                variant="caption"
                            >
                                {title}
                            </Typography>
                            <Typography
                                color={active ? theme.palette.primaryScale[900] : theme.palette.primaryScale[500]}
                                lineHeight={'130%'}
                                variant="subcaption"
                                className={`${classes.elementVisible} set-hidden`}
                            >
                                {subtitle}
                            </Typography>
                        </div>
                        {progress !== null ? (
                            <div className={`${classes.elementVisible} set-hidden`}>
                                <LinearProgress
                                    data-testid="linear-progress"
                                    style={{
                                        backgroundColor: active
                                            ? theme.palette.primaryScale[200]
                                            : theme.palette.success.dark,
                                    }}
                                    progress={progress}
                                />
                            </div>
                        ) : null}
                        <div className={`${classes.endIcon} ${classes.elementHidden} set-visible `}>
                            <IconArrowRight className={classes.arrowIcon} />
                        </div>
                    </div>
                )}
                {status === 'not-started' && (
                    <div
                        data-testid="not-started-nav-item"
                        className={`${classes.container} ${active ? classes.activeContainer : classes.containerHover}`}
                        onClick={goToPath}
                    >
                        {startIcon && (
                            <BoxedIcon
                                data-testid="start-icon"
                                style={{
                                    backgroundColor: theme.palette.primaryScale[800],
                                }}
                                icon={startIcon}
                            />
                        )}
                        <div className={classes.title}>
                            <Typography
                                color={active ? theme.palette.darkScale[900] : theme.palette.darkScale[50]}
                                variant="caption"
                            >
                                {title}
                            </Typography>
                        </div>
                        <div className={`${classes.endIcon} ${classes.elementHidden} set-visible `}>
                            <IconArrowRight className={classes.arrowIcon} />
                        </div>
                    </div>
                )}
                {status === 'disabled' && (
                    <div
                        data-testid="disabled-nav-item"
                        className={`${classes.container} ${classes.disabledContainer}`}
                    >
                        <div>
                            {startIcon && (
                                <BoxedIcon
                                    style={{
                                        backgroundColor: theme.palette.primaryScale[800],
                                    }}
                                    icon={startIcon}
                                />
                            )}
                        </div>
                        <div className={classes.title}>
                            <Typography color={theme.palette.primaryScale[500]} variant="caption">
                                {title}
                            </Typography>
                        </div>
                    </div>
                )}
            </Tooltip>
        </>
    );
};
