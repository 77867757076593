import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const resultsSummarySeasonSelectionSlice = createSlice({
    name: 'resultsSummarySeason',
    initialState: {
        selectedSeasonId: null as number | null,
    },
    reducers: {
        setResultsSummarySelectedSeasonId(state, action: PayloadAction<number | null>) {
            state.selectedSeasonId = action.payload;
        },
    },
});

export const { setResultsSummarySelectedSeasonId } = resultsSummarySeasonSelectionSlice.actions;
