import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsEarningNoDataStyle = makeStyles()((theme) => ({
    content: {
        padding: 0,
        gap: 0,
    },
    container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column',
        gap: theme.spacing(3),
        padding: theme.spacing(5),
    },
    image: {
        filter: 'grayscale(100%)',
        width: '120px',
        height: '120px',
    },
    text: { paddingTop: theme.spacing(1) },
}));
