import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMineralFertilisationGraphsStyles = makeStyles()((theme) => ({
    horizontalContainer: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(3),
    },
    verticalContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    graphContainer: {
        height: '400px',
        width: '100%',
    },
    tableContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    skeletonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    skeletonHeaderContainer: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(4),
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: `1px solid ${theme.palette.darkScale[900]}`,
        borderBottom: `1px solid ${theme.palette.darkScale[900]}`,
        padding: theme.spacing(2),
    },
    skeletonRowContainer: {
        width: '100%',
        display: 'flex',
        gap: theme.spacing(4),
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(2),
    },
    skeletonRowWithBackground: {
        backgroundColor: theme.palette.primaryScale[100],
    },
    noPadding: { gap: 0, paddingBottom: 0 },
}));
