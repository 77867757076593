import createDefaultState from '../createDefaultState';
import geometryDataApi from './geometryData.api';
import { geometryDataCacheAdapter } from './geometryData.cache';
import { GeometryDataEndpointParamsT } from './geometryData.types';

export default ({ farmSeasonId }: GeometryDataEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(geometryDataCacheAdapter, () =>
        geometryDataApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    return {
        geometryDataState: {
            ...defaultState,
        },
    };
};
