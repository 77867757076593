import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { Grid, Skeleton } from '@soil-capital/ui-kit/material-core';

export const ResultsEarningSkeleton = () => {
    return (
        <PageWithHeader
            header={
                <Grid display="flex" justifyContent="space-between" width="100%">
                    <Skeleton width="100px" height="50px" variant="text" />
                    <Skeleton width="273px" height="50px" variant="text" />
                </Grid>
            }
            content={
                <div>
                    <Skeleton variant="text" width="80%" height="60px" />
                    <Skeleton width="100%" height="400px" />
                </div>
            }
        ></PageWithHeader>
    );
};
