import { useNavigate, useSearchParams } from 'react-router-dom';
import { rotationRoutesConfig } from '../../rotation.routes';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import {
    ROTATION_PROGRESS_STATUSES,
    RotationProgressStatusT,
} from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/useIntroductionContentProgress';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import {
    changeActiveFilter,
    changeFieldId,
    FilterType,
    ROTATION_FIELDS_FILTERS,
} from '@modules/encoding/modules/rotation/store/filter.slice';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetGeometryDatas } from '@shared/hooks/useGetGeometryData';

type PropsT = {
    status: RotationProgressStatusT;
    maxCropDestinationProgress: number;
    isFieldCropAssignationDone: boolean;
};
type RotationFieldFilterType = (typeof ROTATION_FIELDS_FILTERS)[keyof typeof ROTATION_FIELDS_FILTERS];

export const useIntroductionContentPageLogic = ({
    status = 'PENDING',
    maxCropDestinationProgress,
    isFieldCropAssignationDone,
}: PropsT) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const isOpenURLparam = searchParams.get('open');
    const [open, setOpen] = useState(isOpenURLparam === 'true');
    const fieldIdURLParam = searchParams.get('fieldId');
    const filterURLParam = searchParams.get('filter');
    const fieldIdParam = fieldIdURLParam ? Number(fieldIdURLParam) : null;
    const filterParam = Object.values(ROTATION_FIELDS_FILTERS).includes(filterURLParam as RotationFieldFilterType)
        ? (filterURLParam as FilterType)
        : null;
    const handleOpen = () => setOpen(true);
    const { currentSeason } = useCurrentSeason();
    const { geometryDataState } = useGetGeometryDatas();
    const areInvalidFields = !!geometryDataState?.list.length;
    const currentCarbonProgrammeYear = currentSeason?.carbon_programme_year ?? 0;
    const shouldShowCropDestination = maxCropDestinationProgress > 0 && currentCarbonProgrammeYear > 0;

    const handleOpenWithFieldId = (farmSeasonFieldId: number) => {
        dispatch(changeFieldId(farmSeasonFieldId));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleRedirectToPac = () => navigate(rotationRoutesConfig.pacImport);
    const handleRedirectToCropDestination = () => navigate(rotationRoutesConfig.cropDestination);
    const handleRedirectToTechnicalItinerary = () =>
        navigate(`../${encodingRoutesConfig.TECHNICAL_ITINERARY}/${technicalItineraryRoutes.introduction()}`);

    const handleContinue = () => {
        if (status === ROTATION_PROGRESS_STATUSES.PENDING && isFieldCropAssignationDone && shouldShowCropDestination)
            return handleRedirectToCropDestination();
        if (status === ROTATION_PROGRESS_STATUSES.FINISHED) return handleRedirectToTechnicalItinerary();
        if (status === ROTATION_PROGRESS_STATUSES.PENDING) return handleOpen();
        return handleRedirectToPac();
    };

    useEffect(() => {
        if (isOpenURLparam === 'true') handleOpen();

        if (fieldIdParam) {
            dispatch(changeFieldId(fieldIdParam));
        }
        if (filterParam) {
            dispatch(changeActiveFilter(filterParam));
        }
    }, [isOpenURLparam, open, fieldIdParam, dispatch, filterParam, searchParams]);

    return {
        open,
        handleOpen,
        handleOpenWithFieldId,
        handleContinue,
        handleClose,
        handleRedirectToPac,
        shouldShowCropDestination,
        handleRedirectToCropDestination,
        areInvalidFields,
    };
};
