import { Button } from '@soil-capital/ui-kit/components';

type MissingFieldButtonPropsT = {
    uniqueKey: string;
    isAdded?: boolean;
    isLoading?: boolean;
    onAddClick: () => void;
    onOpenClick: () => void;
    addedLabel: string;
    missingLabel: string;
};

export const MissingFieldButton = ({
    isAdded = false,
    isLoading = false,
    onAddClick,
    onOpenClick,
    addedLabel,
    missingLabel,
}: MissingFieldButtonPropsT) => (
    <Button variant="contained" size="small" loading={isLoading} onClick={isAdded ? onOpenClick : onAddClick}>
        {isAdded ? addedLabel : missingLabel}
    </Button>
);
