import { deleteLayersByPattern } from '../utils/polygonHelper';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { MapContext } from '@shared/map/utils/MapProvider';
import mapboxgl from 'mapbox-gl';
import { getTextLayerProps, getTextLayoutLabel } from '../utils/layerHelper';
import { POLYGON_SLUGS, cropNamesT } from '../types/mapTypes';
import { useFormContext } from 'react-hook-form';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { FieldT } from '@shared/entities';
import { FieldWithCropT } from '../types/mapTypes';
import setupCropPalette from '../utils/setupCropPalette';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import { useGetFarmSeasonBoundariesQuery } from '@modules/encoding/api/farmSeasonBoundaries';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export type OnPolygonClickT = (props: { polygonId: number }) => { continueInternalBehavior: boolean };

export const usePolygon = (
    isSelectable?: boolean,
    sourceField?: DuplicationFieldT,
    /**
     * Handle action on polygon click from outside.
     * If return continueInternalBehavior: true, it will continue the internal actions (fill forms, update map state...)
     */
    onPolygonClick?: OnPolygonClickT,
) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const mapContext = useContext(MapContext);
    const [hoveredFeatureFieldOverview, setHoveredFeatureFieldOverview] = useState<FieldPolygonT | undefined>();

    if (!mapContext) throw new Error('usePolygon should be used inside a MapProvider');

    const methods = useFormContext();

    const {
        map,
        fields,
        permanentFields,
        selectedFields,
        invalidFields,
        setSelectedFields,
        splitMode,
        setFarmBoundaries,
    } = mapContext;

    const { data: farmSeasonBoundaries, isFetching } = useGetFarmSeasonBoundariesQuery({
        farmSeasonId: currentSeasonId,
    });

    const splitModeRef = useRef(splitMode);

    const treePattern = new Image(72, 72);
    treePattern.src = '/assets/patterns/tree.svg';

    treePattern.onload = () => {
        if (map && !map.hasImage('trees')) map.addImage('trees', treePattern, { pixelRatio: 2 });
    };

    const addPolygonLayer = useCallback(() => {
        if (!map) return;

        if (permanentFields) {
            const fieldsIds = permanentFields.map((field) => field.id);
            deleteLayersByPattern(map, POLYGON_SLUGS.PERMANENT_POLYGON, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.PERMANENT_POLYGON_OUTLINE, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.PERMANENT_POLYGON_TEXT, fieldsIds);

            let computedCropNames = '';
            permanentFields.forEach((field: FieldT | DuplicationFieldT | FieldWithCropT) => {
                if ('crops' in field) {
                    computedCropNames = field.crops.map((crop: cropNamesT) => crop.name).join(' • ');
                }

                const fillLayerId = `${POLYGON_SLUGS.PERMANENT_POLYGON}-${field.id}`;
                const outlineLayerId = `${POLYGON_SLUGS.PERMANENT_POLYGON_OUTLINE}-${field.id}`;
                const textLayerId = `${POLYGON_SLUGS.PERMANENT_POLYGON_TEXT}-${field.id}`;
                const patternLayerId = `${POLYGON_SLUGS.PERMANENT_POLYGON_PATTERN}-${field.id}`;

                const fillLayer = map.getLayer(fillLayerId);
                if (!fillLayer) {
                    map.addLayer({
                        id: fillLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-color': '#aaaaaa',
                            'fill-opacity': 0.6,
                        },
                    });
                } else {
                    map.setPaintProperty(fillLayerId, 'fill-color', '#aaaaaa');
                    (map.getSource(fillLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const outlineLayer = map.getLayer(outlineLayerId);
                if (!outlineLayer) {
                    map.addLayer({
                        id: outlineLayerId,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'line-color': '#aaaaaa',
                            'line-width': 1.5,
                        },
                    });
                } else {
                    map.setPaintProperty(outlineLayerId, 'line-color', '#aaaaaa');
                    (map.getSource(outlineLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const patternLayer = map.getLayer(patternLayerId);
                if (!patternLayer) {
                    map.addLayer({
                        id: patternLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-opacity': field?.has_agroforestry ? 1 : 0,
                            'fill-pattern': field?.has_agroforestry ? 'trees' : '',
                        },
                    });
                }

                if (!map.getLayer(textLayerId)) {
                    map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.PERMANENT_POLYGON_TEXT, computedCropNames));
                } else {
                    const newTextLayoutLabel = getTextLayoutLabel(field, computedCropNames);
                    map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    (map.getSource(textLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }
            });
        }

        if (fields) {
            const fieldsIds = fields.map((field) => field.id);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON_OUTLINE, fieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.POLYGON_TEXT, fieldsIds);

            let computedCropNames = '';
            let cropColors: { [key: number]: string } = {};
            if (fields.some((field) => 'crops' in field)) {
                const fieldsWithCrop = fields.filter((field): field is FieldWithCropT => 'crops' in field);
                cropColors = setupCropPalette(fieldsWithCrop as FieldWithCropT[]);
            }
            fields.forEach((field: FieldT | DuplicationFieldT | FieldWithCropT) => {
                let hasCrop = false;
                let fieldCropColor = 'white';
                if ('crops' in field) {
                    computedCropNames = field.crops.map((crop: cropNamesT) => crop.name).join(' • ');
                    hasCrop = field.crops.length > 0;
                    fieldCropColor = hasCrop ? cropColors[field.crops[0].id] : 'white';
                }

                const fillLayerId = `${POLYGON_SLUGS.POLYGON}-${field.id}`;
                const outlineLayerId = `${POLYGON_SLUGS.POLYGON_OUTLINE}-${field.id}`;
                const textLayerId = `${POLYGON_SLUGS.POLYGON_TEXT}-${field.id}`;
                const patternLayerId = `${POLYGON_SLUGS.POLYGON_PATTERN}-${field.id}`;

                map.on('mousemove', fillLayerId, function (e) {
                    const features = map.queryRenderedFeatures(e.point, {
                        layers: [fillLayerId],
                    });
                    if (features.length > 0 && !splitModeRef.current) {
                        setHoveredFeatureFieldOverview(field.polygon);
                    } else {
                        if (!splitModeRef.current) map.getCanvas().style.cursor = '';
                        setHoveredFeatureFieldOverview(undefined);
                    }
                });

                map.on('mouseleave', fillLayerId, function () {
                    if (!splitModeRef.current) map.getCanvas().style.cursor = '';
                    setHoveredFeatureFieldOverview(undefined);
                });

                const fillLayer = map.getLayer(fillLayerId);
                if (!fillLayer) {
                    map.addLayer({
                        id: fillLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-color': fieldCropColor,
                            'fill-opacity': 0.6,
                        },
                    });
                } else {
                    map.setPaintProperty(fillLayerId, 'fill-color', fieldCropColor);
                    (map.getSource(fillLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const outlineLayer = map.getLayer(outlineLayerId);
                if (!outlineLayer) {
                    map.addLayer({
                        id: outlineLayerId,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'line-color': fieldCropColor,
                            'line-width': 1.5,
                        },
                    });
                } else {
                    map.setPaintProperty(outlineLayerId, 'line-color', fieldCropColor);
                    (map.getSource(outlineLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const patternLayer = map.getLayer(patternLayerId);
                if (!patternLayer) {
                    map.addLayer({
                        id: patternLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-opacity': field?.has_agroforestry ? 1 : 0,
                            'fill-pattern': field?.has_agroforestry ? 'trees' : '',
                        },
                    });
                }

                if (!map.getLayer(textLayerId)) {
                    map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.POLYGON_TEXT, computedCropNames));
                } else {
                    const newTextLayoutLabel = getTextLayoutLabel(field, computedCropNames);
                    map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    (map.getSource(textLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }
                if (isSelectable) {
                    map.on('mousemove', fillLayerId, function () {
                        map.getCanvas().style.cursor = 'pointer';
                    });

                    map.on('mouseleave', fillLayerId, function () {
                        map.getCanvas().style.cursor = 'default';
                    });
                    map.on('click', `${POLYGON_SLUGS.POLYGON}-${field.id}`, () => {
                        if ('fieldCrop' in field) {
                            if (field.id === sourceField?.id) return;
                            // if the click is not allowed from outside the hook, we stop the action
                            if (onPolygonClick && !onPolygonClick({ polygonId: field.id }).continueInternalBehavior) {
                                return;
                            }
                            const cropId = (field as DuplicationFieldT).fieldCrop.id;
                            const currentCropIds = methods.getValues('target_farm_season_field_crop_ids');
                            if (!currentCropIds.includes(cropId)) {
                                methods.setValue('target_farm_season_field_crop_ids', [...currentCropIds, cropId]);
                            }
                        } else {
                            // if the click is not allowed from outside the hook, we stop the action
                            if (onPolygonClick && !onPolygonClick({ polygonId: field.id }).continueInternalBehavior) {
                                return;
                            }
                            const id = field.id;
                            const currentFieldIds = methods.getValues('selected_field_ids');
                            if (currentFieldIds.includes(id)) return;
                            methods.setValue('selected_field_ids', [...methods.getValues('selected_field_ids'), id]);
                            setSelectedFields?.((prevSelectedFields) => [...prevSelectedFields, field]);
                        }
                    });
                }
            });
        }

        if (invalidFields) {
            const invalidFieldsIds = invalidFields.map((field) => field.id);
            deleteLayersByPattern(map, POLYGON_SLUGS.INVALID_FIELD_POLYGON, invalidFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.INVALID_FIELD_POLYGON_OUTLINE, invalidFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.INVALID_FIELD_POLYGON_TEXT, invalidFieldsIds);

            invalidFields.forEach((field) => {
                const fillLayerId = `${POLYGON_SLUGS.INVALID_FIELD_POLYGON}-${field.id}`;
                const outlineLayerId = `${POLYGON_SLUGS.INVALID_FIELD_POLYGON_OUTLINE}-${field.id}`;
                const textLayerId = `${POLYGON_SLUGS.INVALID_FIELD_POLYGON_TEXT}-${field.id}`;

                const fillLayer = map.getLayer(fillLayerId);
                if (!fillLayer) {
                    map.addLayer({
                        id: fillLayerId,
                        type: 'fill',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'fill-color': 'white',
                            'fill-opacity': 0.6,
                        },
                    });
                } else {
                    map.setPaintProperty(fillLayerId, 'fill-color', 'white');
                    (map.getSource(fillLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }

                const outlineLayer = map.getLayer(outlineLayerId);
                if (!outlineLayer) {
                    map.addLayer({
                        id: outlineLayerId,
                        type: 'line',
                        source: {
                            type: 'geojson',
                            data: field.polygon,
                        },
                        paint: {
                            'line-color': 'red',
                            'line-width': 1.5,
                        },
                    });
                } else {
                    map.setPaintProperty(outlineLayerId, 'line-color', 'red');
                    (map.getSource(outlineLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }
                map?.moveLayer(outlineLayerId);
                if (!map.getLayer(textLayerId)) {
                    map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.POLYGON_TEXT, ''));
                } else {
                    const newTextLayoutLabel = getTextLayoutLabel(field, '');
                    map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    (map.getSource(textLayerId) as mapboxgl.GeoJSONSource).setData(field.polygon);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, isSelectable, map, setSelectedFields, sourceField?.id, methods, permanentFields, invalidFields]);

    const addSelectedPolygonLayer = useCallback(() => {
        if (!map) return;

        if (selectedFields) {
            const selectedFieldsIds = selectedFields.map((field) => field.id);

            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON, selectedFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON_OUTLINE, selectedFieldsIds);
            deleteLayersByPattern(map, POLYGON_SLUGS.SELECTED_POLYGON_TEXT, selectedFieldsIds);

            selectedFields.forEach(
                (field: FieldT | DuplicationFieldT | FieldWithCropT | DuplicationFieldT | FieldWithCropT) => {
                    let cropNames = '';
                    if ('crops' in field) {
                        cropNames = field.crops.map((crop) => crop.name).join(' • ');
                    }
                    const fillLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON}-${field.id}`;
                    const outlineLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON_OUTLINE}-${field.id}`;
                    const textLayerId = `${POLYGON_SLUGS.SELECTED_POLYGON_TEXT}-${field.id}`;

                    if (!map.getLayer(fillLayerId)) {
                        map.addLayer({
                            id: fillLayerId,
                            type: 'fill',
                            source: {
                                type: 'geojson',
                                data: field.polygon,
                            },
                            paint: {
                                'fill-color': 'green',
                                'fill-opacity': 0.6,
                            },
                        });
                    }

                    if (!map.getLayer(outlineLayerId)) {
                        map.addLayer({
                            id: outlineLayerId,
                            type: 'line',
                            source: {
                                type: 'geojson',
                                data: field.polygon,
                            },
                            paint: {
                                'line-color': 'green',
                                'line-width': 1.5,
                            },
                        });
                    }

                    if (!map.getLayer(textLayerId)) {
                        map.addLayer(getTextLayerProps(field, POLYGON_SLUGS.SELECTED_POLYGON_TEXT, cropNames));
                    } else {
                        const newTextLayoutLabel = getTextLayoutLabel(field, cropNames);
                        map.setLayoutProperty(textLayerId, 'text-field', newTextLayoutLabel);
                    }
                    if (isSelectable) {
                        map.on('mousemove', fillLayerId, function () {
                            map.getCanvas().style.cursor = 'pointer';
                        });

                        map.on('mouseleave', fillLayerId, function () {
                            map.getCanvas().style.cursor = 'default';
                        });

                        map.on('click', fillLayerId, () => {
                            //handle the case where the field has a crop (duplication)
                            if ('fieldCrop' in field) {
                                if (field.id === sourceField?.id) return;
                                // if the click is not allowed from outside the hook, we stop the action
                                if (
                                    onPolygonClick &&
                                    !onPolygonClick({ polygonId: field.id }).continueInternalBehavior
                                ) {
                                    return;
                                }
                                const cropId = (field as DuplicationFieldT).fieldCrop.id;
                                const currentCropIds = methods.getValues('target_farm_season_field_crop_ids');
                                const updatedCropIds = currentCropIds.filter((id: number) => id !== cropId);
                                methods.setValue('target_farm_season_field_crop_ids', updatedCropIds);
                            } else {
                                // if the click is not allowed from outside the hook, we stop the action
                                if (
                                    onPolygonClick &&
                                    !onPolygonClick({ polygonId: field.id }).continueInternalBehavior
                                ) {
                                    return;
                                }
                                const fieldId = field.id;
                                const currentfieldIds = methods.getValues('selected_field_ids');
                                const updatedfieldIds = currentfieldIds.filter((id: number) => id !== fieldId);
                                methods.setValue('selected_field_ids', updatedfieldIds);
                                setSelectedFields?.((prevSelectedFields) =>
                                    prevSelectedFields.filter((f) => f.id !== field.id),
                                );
                            }
                        });
                    }
                },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectable, map, selectedFields, setSelectedFields, sourceField?.id, methods]);

    const addFarmBoundariesLayer = useCallback(() => {
        map?.addLayer({
            id: 'farm-boundaries-layer',
            type: 'line',
            source: {
                type: 'geojson',
                data: farmSeasonBoundaries as GeoJSON.FeatureCollection<GeoJSON.Geometry>,
            },
            paint: {
                'line-color': 'blue',
                'line-width': 5,
            },
        });
        map?.moveLayer('farm-boundaries-layer');
    }, [farmSeasonBoundaries, map]);

    useEffect(() => {
        if (map && fields && permanentFields) {
            if (!map.loaded()) {
                map.once('idle', () => {
                    addPolygonLayer();
                });
            } else {
                addPolygonLayer();
            }
        }
        // missing addPolygonLayer in the dependency array because in rotation it causes the map to wrongly update... To be investigated -> comment on FP-6758
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, fields, permanentFields]);

    useEffect(() => {
        if (map && selectedFields) {
            if (!map.loaded()) {
                map.once('idle', () => {
                    addSelectedPolygonLayer();
                });
            } else {
                addSelectedPolygonLayer();
            }
        }
    }, [map, selectedFields, addSelectedPolygonLayer]);

    useEffect(() => {
        if (map && farmSeasonBoundaries && !isFetching) {
            if (!map.loaded()) {
                map.once('idle', () => {
                    setFarmBoundaries(farmSeasonBoundaries);
                    addFarmBoundariesLayer();
                });
            } else {
                addFarmBoundariesLayer();
            }
        }
    }, [farmSeasonBoundaries, isFetching, map, fields, addFarmBoundariesLayer, setFarmBoundaries]);

    return { addPolygonLayer, addSelectedPolygonLayer, hoveredFeatureFieldOverview };
};
