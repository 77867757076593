import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useGetHistoricalFarmSeasonsQuery } from '@modules/encoding/modules/history/api/historyApi';
import { skipToken } from '@reduxjs/toolkit/query';

export const useBaselineFarmSeason = () => {
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const { staticSeasonState } = entities.staticSeason.useState();
    const baselineFarmSeasonId =
        currentSeason?.baseline_farm_season_id === null ? currentSeason?.id : currentSeason?.baseline_farm_season_id;
    const {
        data: historicalFarmSeasons,
        isLoading: isLoadingHistoricalFarmSeasons,
        isFetching: isFetchingHistoricalFarmSeasons,
    } = useGetHistoricalFarmSeasonsQuery(baselineFarmSeasonId ? { baselineFarmSeasonId } : skipToken);

    const baselineSeasonId = currentSeason?.baseline_farm_season_id;
    const baselineSeason = seasonState?.list?.find((fs) => fs.id === baselineSeasonId);

    const isBaseline = currentSeasonLoading ? undefined : currentSeason?.baseline_farm_season_id === null;
    const isHistoryFarmSeason = isLoadingHistoricalFarmSeasons
        ? undefined
        : !!historicalFarmSeasons?.find((fs) => fs.id === currentSeason?.id);
    const baselineHarvestYear = staticSeasonState.getById(baselineSeason?.season_id)?.harvest_year;
    const isYoyFarmSeason = !isBaseline && !isHistoryFarmSeason;

    const isLoading =
        currentFarmLoading ||
        seasonState.isLoading ||
        staticSeasonState.isLoading ||
        isLoadingHistoricalFarmSeasons ||
        currentSeasonLoading;
    const isFetching = seasonState.isFetching || staticSeasonState.isFetching || isFetchingHistoricalFarmSeasons;

    return {
        baselineSeason,
        baselineHarvestYear,
        isBaseline,
        isHistoryFarmSeason,
        isLoading,
        isFetching,
        isYoyFarmSeason,
    };
};
