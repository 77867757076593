import { useGetLegumesQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';

export const useLegumeGraphData = () => {
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const {
        data: legumesData,
        isLoading: isLoadingLegumes,
        isFetching: isFetchingLegumes,
    } = useGetLegumesQuery({ farmSeasonId: selectedSeasonId });

    return {
        legumesData,
        isLoadingLegumes: isLoadingLegumes || isFetchingLegumes,
    };
};
