import api from '@shared/api';
import geometryDataCache from './geometryData.cache';
import { EntityState } from '@reduxjs/toolkit';
import { GeometryDataEndpointParamsT, GeometryDataT } from './geometryData.types';

export const geometryDataApiInstance = api.injectEndpoints({
    endpoints: (builder) => ({
        getGeometryData: builder.query<EntityState<GeometryDataT>, GeometryDataEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/geometry-data`,
                method: 'GET',
            }),
            providesTags: ['GeometryData'],
            transformResponse: geometryDataCache.transformResponse,
        }),
        deleteGeometryData: builder.mutation<EntityState<GeometryDataT>, GeometryDataEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/geometry-data/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: geometryDataCache.remove('cache-only'),
        }),
        deleteAllGeometryData: builder.mutation<EntityState<GeometryDataT>, GeometryDataEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/geometry-data`,
                method: 'DELETE',
            }),
            onQueryStarted: geometryDataCache.removeAll('fetch-only'),
        }),
    }),
});

export default {
    useGet: geometryDataApiInstance.useGetGeometryDataQuery,
    useDelete: geometryDataApiInstance.useDeleteGeometryDataMutation,
    useDeleteAll: geometryDataApiInstance.useDeleteAllGeometryDataMutation,
};
