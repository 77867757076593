import { auth } from '@modules/auth';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetMissingFieldsAndPermanentCropForHistorySeasonsQuery } from '../../api/alarmingDataApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useIsEncodingDone } from '@shared/hooks/useIsEncodingDone';
import { ROTATION_FIELDS_FILTERS } from '../../store/filter.slice';

type MissingFieldsAddedT = {
    [key: string]: boolean;
};

export const useProblematicFieldListLogic = () => {
    const navigate = useNavigate();
    const canSeeAlarmingData = auth.usePermission('canSeeAlarmingData');
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason } = useCurrentSeason();
    const [missingFieldsAdded, setMissingFieldsAdded] = useState<MissingFieldsAddedT | null>(null);
    const [missingFieldsLoading, setMissingFieldsLoading] = useState<MissingFieldsAddedT | null>(null);
    const baselineFarmSeasonId =
        currentSeason?.baseline_farm_season_id === null ? currentSeason?.id : currentSeason?.baseline_farm_season_id;

    const { isEncodingDone } = useIsEncodingDone(baselineFarmSeasonId);
    const { data, isLoading, refetch } = useGetMissingFieldsAndPermanentCropForHistorySeasonsQuery(
        {
            farmSeasonId: baselineFarmSeasonId ?? 0,
        },
        { skip: !baselineFarmSeasonId || !canSeeAlarmingData },
    );
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { fieldState } = entities.field.useState({ farmSeasonId: baselineFarmSeasonId });
    const [addMissingFieldMutation, { isLoading: isPostAddMissingFieldLoading }] = entities.field.useAddMissingField();
    const problematicData = data?.filter((item) =>
        item.values.some((group) =>
            group.values.some((field) => field.value === 'PERMANENT' || field.value === 'MISSING_FIELD'),
        ),
    );
    const problematicFieldCount = problematicData?.length ?? 0;

    const getSeasonIdByYear = (yearParam: 'Y-2' | 'Y-1' | 'Y0' | 'Y1' | 'Y2' | 'Y3' | 'Y4' | 'Y5') => {
        const year = Number(yearParam.replace('Y', ''));
        const season = seasonState.list.find((season) => season.carbon_programme_year === year);
        return season?.id;
    };
    const getHarvestNameByYear = (yearParam: 'Y-2' | 'Y-1' | 'Y0' | 'Y1' | 'Y2' | 'Y3' | 'Y4' | 'Y5') => {
        const year = Number(yearParam.replace('Y', ''));
        const season = seasonState.list.find((season) => season.carbon_programme_year === year);
        return season?.harvest_year;
    };

    const addMissingField = async (farmSeasonFieldId: number, targetFarmSeasonId: number) => {
        const newFieldKey = `${farmSeasonFieldId}-${targetFarmSeasonId}`;
        setMissingFieldsLoading((prevStatus) => ({
            ...prevStatus,
            [newFieldKey]: true,
        }));
        const response = await addMissingFieldMutation({
            farmSeasonId: currentSeasonId,
            farmSeasonFieldId: farmSeasonFieldId,
            targetFarmSeasonId: targetFarmSeasonId,
        });
        setMissingFieldsLoading((prevStatus) => ({
            ...prevStatus,
            [newFieldKey]: false,
        }));
        if (response && 'data' in response) {
            setMissingFieldsAdded((prevStatus) => ({
                ...prevStatus,
                [newFieldKey]: true,
            }));
        } else {
            enqueueSnackbar(t('encoding.rotation.problematic-field.error-add-missing-field'), { variant: 'error' });
        }
    };
    const openFieldCropAssignationModal = (farmSeasonId: number | undefined, fieldId: number) => {
        if (farmSeasonId) {
            navigate(`/${farmSeasonId}/rotation?open=true&fieldId=${fieldId}`);
        }
    };
    const openFieldCropAssignationModalWithFieldsWithoutCrop = (farmSeasonId: number) => {
        navigate(`/${farmSeasonId}/rotation?open=true&filter=${ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP}`);
    };

    return {
        canSeeAlarmingData,
        t,
        currentSeasonId,
        data,
        isLoading,
        refetch,
        currentFarm,
        currentFarmLoading,
        seasonState,
        fieldState,
        addMissingField,
        isPostAddMissingFieldLoading,
        problematicFieldCount,
        getSeasonIdByYear,
        getHarvestNameByYear,
        openFieldCropAssignationModalWithFieldsWithoutCrop,
        missingFieldsAdded,
        missingFieldsLoading,
        isEncodingDone,
        openFieldCropAssignationModal,
    };
};
