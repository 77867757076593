export type TagNameT = (typeof entityTags)[number];

export const entityTags = [
    'Field',
    'Crop',
    'FieldCrop',
    'FieldCropsToFinalise',
    'FieldCropsThatNeedFinalisation',
    'Farm',
    'Irrigation',
    'Season',
    'Operation',
    'Operations',
    'FarmSeasonProfile',
    'Progress',
    'StaticFertiliser',
    'HistoryDuplicationData',
    'Results',
    'Livestock',
    'DuplicationSeasonSource',
    'Tree',
    'TotalArea',
    'GeometryData',
] as const;
