import { BaseEntityT } from '../baseEntity.types';

export type OperationableTypeT =
    | 'App\\Models\\FarmSeasonOperationOther'
    | 'App\\Models\\FarmSeasonOperationTillage'
    | 'App\\Models\\FarmSeasonOperationHarvesting'
    | 'App\\Models\\FarmSeasonOperationFertilising'
    | 'App\\Models\\FarmSeasonOperationSpraying'
    | 'App\\Models\\FarmSeasonOperationSowing'
    | 'App\\Models\\FarmSeasonOperationCoverCropping';

export const OPERATION_TYPE_SLUGS = {
    SPRAYING: 'spraying',
    SPRAYING_ALL: 'spraying-all',
    FERTILISATION: 'fertilisation',
    FERTILISATION_ORGANIC: 'fertilisation-organic',
    FERTILISATION_MINERAL: 'fertilisation-mineral',
    COVER_CROP_MANAGEMENT: 'cover-crop-management',
    COVER_CROP_MANAGEMENT_SEEDING: 'cover-crop-management-seeding',
    COVER_CROP_MANAGEMENT_DESTRUCTION: 'cover-crop-management-destruction',
    SOIL_WORK: 'soil-work',
    SOIL_WORK_WEEDING_MACHINE: 'soil-work-weeding-machine',
    SOIL_WORK_DEEP_WORK_MACHINE: 'soil-work-deep-work-machine',
    SOIL_WORK_SHALLOW_WORK_MACHINE: 'soil-work-shallow-work-machine',
    SOIL_WORK_ROLLER: 'soil-work-roller',
    SOIL_WORK_PLOUGH: 'soil-work-plough',
    SEEDING: 'seeding',
    SEEDING_SEEDING: 'seeding-seeding',
    SEEDING_COMPANION_SEEDING: 'seeding-companion-seeding',
    HARVEST: 'harvest',
    HARVEST_CREATION: 'harvest-creation',
    HARVEST_DESTRUCTION: 'harvest-destruction',
    MAINTENANCE_SHREDDING: 'maintenance-shredding',
} as const;

export type OperationTypeSlugT = (typeof OPERATION_TYPE_SLUGS)[keyof typeof OPERATION_TYPE_SLUGS];

export type StaticOperationTypeT = {
    name: string;
    parent_id: number | null;
    translation_slug: string;
    slug: OperationTypeSlugT;
    operationable_type: OperationableTypeT;
    order: number;
} & BaseEntityT;
