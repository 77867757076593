import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { IconClose, IconMerge } from '@soil-capital/ui-kit/icons';
import { IconCheckboxChecked } from '@soil-capital/ui-kit/icons';
import { useMerge } from '@shared/map/hook/useMerge';
import { MergedFieldModal } from '@modules/encoding/modules/rotation/components/MergedFieldModal/MergedFieldModal';

export const MapMerge = ({ readOnly }: { readOnly: boolean }) => {
    const {
        merge,
        stopMerging,
        fields,
        editMode,
        drawMode,
        mergeMode,
        onFinishMerge,
        newMergedPolygon,
        selectedFields,
        mergedFieldModalOpen,
        onMergedFieldModalClose,
        splitMode,
        invalidFieldsNumber,
    } = useMerge();
    const { t } = useTranslation();

    const hasPolygons = fields?.length;

    return (
        <>
            {hasPolygons && !mergeMode && !editMode && !drawMode && !splitMode ? (
                <Tooltip text={invalidFieldsNumber ? t('encoding-rotation.introduction.invalid-fields-tooltip') : ''}>
                    <Button
                        startIcon={<IconMerge sx={{ width: '20px', height: '20px' }} />}
                        sx={{ whiteSpace: 'nowrap' }}
                        onClick={merge}
                        disabled={readOnly || !!invalidFieldsNumber}
                    >
                        {t('encoding-rotation.map.merge-field')}
                    </Button>
                </Tooltip>
            ) : (
                <></>
            )}
            {mergeMode && (
                <>
                    <Button
                        startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />}
                        onClick={() => stopMerging(true)}
                    >
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!selectedFields || selectedFields?.length < 2}
                        variant="contained"
                        onClick={() => onFinishMerge()}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            )}
            {newMergedPolygon && (
                <MergedFieldModal
                    polygon={newMergedPolygon}
                    open={mergedFieldModalOpen}
                    onClose={onMergedFieldModalClose}
                />
            )}
        </>
    );
};
