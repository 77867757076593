import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsCardStyles = makeStyles<{ hasBorders: boolean }>()((theme, { hasBorders }) => ({
    container: {
        width: '100%',
        border: hasBorders ? '1px solid' : 'none',
        borderColor: theme.palette.darkScale[300],
        borderRadius: theme.shape.borderRadiusM,
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    elementContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    subtitle: {
        color: theme.palette.darkScale[500],
        textTransform: 'uppercase',
        fontSize: theme.typography.caption.fontSize,
    },
}));
