import { Button, Card } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useProblematicFieldListLogic } from './useProblematicFieldList.logic';
import { useProblematicFieldListStyles } from './ProblematicFieldList.style';
import { IconWarning } from '@soil-capital/ui-kit/icons';
import { MissingFieldButton } from '../MissingFieldButton/MissingFieldButton';

export const ProblematicFieldList = () => {
    const {
        canSeeAlarmingData,
        t,
        data,
        fieldState,
        missingFieldsLoading,
        problematicFieldCount,
        getSeasonIdByYear,
        getHarvestNameByYear,
        addMissingField,
        openFieldCropAssignationModalWithFieldsWithoutCrop,
        missingFieldsAdded,
        isEncodingDone,
        openFieldCropAssignationModal,
        refetch,
    } = useProblematicFieldListLogic();
    const { classes } = useProblematicFieldListStyles();

    if (
        !canSeeAlarmingData ||
        problematicFieldCount === 0 ||
        (!isEncodingDone && !missingFieldsAdded) ||
        fieldState.isLoading
    ) {
        return null;
    }

    return (
        <Card className={classes.container}>
            <div className={classes.title}>
                <IconWarning color="warning" />
                <Typography>
                    {t(
                        `encoding-rotation.problematic-field.title-${
                            problematicFieldCount === 1 ? 'singular' : 'plural'
                        }`,
                        { fieldNumber: problematicFieldCount },
                    )}
                </Typography>
            </div>
            {data?.map((item, index) => (
                <div key={`item-${item.farm_season_field_id}-${index}`} className={classes.fullWidth}>
                    <Typography variant="body2">
                        {t('encoding-rotation.problematic-field.problematic-field', {
                            fieldName: `${fieldState.getById(item.farm_season_field_id)?.name} (${
                                fieldState.getById(item.farm_season_field_id)?.area
                            } ${t('constants.hectare-unit')})`,
                        })}
                    </Typography>
                    {item.values.map((group, groupIndex) => {
                        const seasonId = getSeasonIdByYear(group.groupName);

                        return (
                            <div key={`${item.farm_season_field_id}-${group.groupName}-${groupIndex}`}>
                                {group.values.map((field, fieldIndex) => {
                                    const uniqueKey = `${item.farm_season_field_id}-${seasonId}`;

                                    return (
                                        <ul
                                            key={`${item.farm_season_field_id}-${group.groupName}-${field.name}-${fieldIndex}`}
                                        >
                                            {field.value === 'MISSING_FIELD' && (
                                                <li>
                                                    <div className={classes.flexbox}>
                                                        <Typography variant="body2">
                                                            {t('encoding-rotation.problematic-field.missing-field', {
                                                                harvestYear: getHarvestNameByYear(group.groupName),
                                                            })}
                                                        </Typography>
                                                        <MissingFieldButton
                                                            uniqueKey={uniqueKey}
                                                            isAdded={!!missingFieldsAdded?.[uniqueKey]}
                                                            isLoading={missingFieldsLoading?.[uniqueKey]}
                                                            onAddClick={() =>
                                                                addMissingField(
                                                                    item.farm_season_field_id,
                                                                    seasonId ?? 0,
                                                                )
                                                            }
                                                            onOpenClick={() => {
                                                                openFieldCropAssignationModalWithFieldsWithoutCrop(
                                                                    seasonId ?? 0,
                                                                );
                                                                refetch();
                                                            }}
                                                            addedLabel={t(
                                                                'encoding-rotation.problematic-field.field-added-cta',
                                                            )}
                                                            missingLabel={t(
                                                                'encoding-rotation.problematic-field.missing-field.cta',
                                                            )}
                                                        />
                                                    </div>
                                                </li>
                                            )}
                                            {field.value === 'PERMANENT' && (
                                                <li>
                                                    <div className={classes.flexbox}>
                                                        <Typography variant="body2">
                                                            {t('encoding-rotation.problematic-field.crop-permanence', {
                                                                fieldName: field.name,
                                                                harvestYear: getHarvestNameByYear(group.groupName),
                                                            })}
                                                        </Typography>
                                                        <Button
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => {
                                                                openFieldCropAssignationModal(
                                                                    seasonId,
                                                                    field.fieldId ?? item.farm_season_field_id,
                                                                );
                                                            }}
                                                            sx={{ whiteSpace: 'nowrap' }}
                                                        >
                                                            {t(
                                                                'encoding-rotation.problematic-field.crop-permanence.cta',
                                                            )}
                                                        </Button>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            ))}
        </Card>
    );
};
