import { useContext, useEffect, useState } from 'react';
import { MapContext } from '../utils/MapProvider';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Feature, Geometry, Polygon } from '@turf/helpers';
import { deleteLayer } from '../utils/polygonHelper';
import { isDrawingOnOtherPolygons, isDrawingInsideBoundaries } from '../utils/polygonHelper';
import { calculateArea } from '../utils/polygonHelper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useDraw = () => {
    const mapContext = useContext(MapContext);
    const {
        map,
        fields,
        permanentFields,
        setDrawMode,
        drawMode,
        editMode,
        splitMode,
        mergeMode,
        farmBoundaries,
        invalidFields,
    } = mapContext ?? {};
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const polygons = [...(fields ?? []), ...(permanentFields ?? [])].map(
        (field) => field.polygon.geometry,
    ) as Polygon[];
    const invalidFieldsNumber = invalidFields?.length;
    const [drawnPolygon, setDrawnPolygon] = useState<Feature<Polygon> | null>(null);
    const [polygonArea, setPolygonArea] = useState<number | null>(null);
    const [drawControl, setDrawControl] = useState<null | MapboxDraw>(null);
    const drawOptions = new MapboxDraw({
        displayControlsDefault: false,
    });
    const [isSaveDisabled, setIsSavedDisabled] = useState(false);

    const draw = () => {
        if (map) {
            setIsSavedDisabled(false);
            closeSnackbar();
            setDrawMode?.(true);
            if (drawControl) {
                map.removeControl(drawControl);
            }
            map.addControl(drawOptions);
            setDrawControl(drawOptions);
            drawOptions.changeMode('draw_polygon');
            map.getCanvas().style.cursor = 'url("/assets/images/pencil-02.svg") 0 22, crosshair';

            map.on('draw.create', (event) => {
                const features: Feature<Polygon>[] = event.features;
                const drawingInsideBoundaries = isDrawingInsideBoundaries(features[0].geometry, farmBoundaries);
                const drawingOnOtherPolygons = isDrawingOnOtherPolygons(features[0].geometry, polygons);
                if (drawingInsideBoundaries && !drawingOnOtherPolygons) {
                    setDrawnPolygon(features[0]);
                    addDrawnLayer(features[0]);
                    setPolygonArea(calculateArea(features[0].geometry));
                } else if (drawingOnOtherPolygons) {
                    setIsSavedDisabled(true);
                    enqueueSnackbar(t('encoding-rotation.map.draw.overlap-error'), {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                } else if (!drawingInsideBoundaries) {
                    setIsSavedDisabled(true);
                    enqueueSnackbar(t('encoding-rotation.map.draw.boundaries-error'), {
                        variant: 'error',
                        preventDuplicate: true,
                    });
                }
            });
        }
    };

    const stopDrawingMode = () => {
        if (drawControl && map) {
            map.removeControl(drawControl);
            setDrawControl(null);
            map.getCanvas().style.cursor = 'default';
        }
    };

    const stopDrawing = (deletePolygonLayer?: boolean) => {
        stopDrawingMode();
        setDrawMode?.(false);
        if (drawnPolygon && map) {
            if (deletePolygonLayer) {
                deleteLayer(map, `drawn-${drawnPolygon.id}`);
            }
            setDrawnPolygon(null);
        }
    };

    const addDrawnLayer = (polygon: Feature<Geometry>) => {
        if (map && polygon) {
            if (map.getLayer(`drawn-${polygon.id}`)) {
                deleteLayer(map, `drawn-${polygon.id}`);
            }
            map.addLayer({
                id: `drawn-${polygon.id}`,
                type: 'fill',
                source: {
                    type: 'geojson',
                    data: polygon.geometry as Polygon,
                },
                paint: {
                    'fill-color': '#FB9537',
                    'fill-opacity': 1,
                },
            });
        }
    };

    useEffect(() => {
        if (drawControl && map) {
            stopDrawingMode();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [drawnPolygon]);

    return {
        drawnPolygon,
        polygonArea,
        draw,
        stopDrawing,
        drawMode,
        editMode,
        splitMode,
        mergeMode,
        isSaveDisabled,
        invalidFieldsNumber,
    };
};
