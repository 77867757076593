import { useGetIrrigationDataQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useIrrigationGraph = () => {
    const { t } = useTranslation();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);

    const {
        data: irrigationData,
        isLoading: isLoadingIrrigationData,
        isFetching: isFetchingIrrigationData,
    } = useGetIrrigationDataQuery({
        farmSeasonId: selectedSeasonId,
    });

    const percentageIrrigatedFormatted = useMemo(() => {
        return [
            {
                title: `${irrigationData?.percentage_irrigated} %` || '0%',
                subtitle: t('results.summary.emissions.irrigation-subtitle'),
            },
        ];
    }, [irrigationData?.percentage_irrigated, t]);

    const formattedIrrigationPerCrop = useMemo(() => {
        if (irrigationData?.irrigation_per_crop) {
            const headers = [
                t('constants.crop'),
                t('results.summary.emissions.irrigation-table-area-percentage'),
                t('constants.unit.ha'),
                t('constants.unit.milimeters-ha'),
            ];
            const data = irrigationData?.irrigation_per_crop?.map((crop) => {
                return [
                    t(`db.crop.slug-${crop.slug}`),
                    `${Number(crop.percentage_irrigated).toFixed(0)} %`,
                    Number(crop.total_area).toFixed(2),
                    Number(crop.mm_per_ha).toFixed(2),
                ];
            });
            return {
                headers,
                data,
            };
        } else {
            return {
                headers: [],
                data: [[]],
            };
        }
    }, [irrigationData?.irrigation_per_crop, t]);

    const hasNoData = useMemo(() => {
        return (
            irrigationData?.irrigation_per_crop.length === 0 && !isLoadingIrrigationData && !isFetchingIrrigationData
        );
    }, [irrigationData?.irrigation_per_crop.length, isFetchingIrrigationData, isLoadingIrrigationData]);

    return {
        percentageIrrigatedFormatted,
        formattedIrrigationPerCrop,
        isLoading: isLoadingIrrigationData || isFetchingIrrigationData,
        hasNoData,
    };
};
