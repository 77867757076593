import { useEmissionsSummaryStyles } from './EmissionsSummary.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { IconArrowEmission } from '@soil-capital/ui-kit/icons';
import { MineralFertilisationGraphs } from '../MineralFertilisationGraphs/MineralFertilisationGraphs';
import { TillagePracticeGraph } from '../TillagePracticeGraph/TillagePracticeGraph';
import { FuelConsumptionGraph } from '../FuelComsumptionGraph/FuelComsumptionGraph';
import { LivestockGraph } from '../LivestockGraph/LivestockGraph';
import { IrrigationGraph } from '../IrrigationGraph/IrrigationGraph';
import { ChalkGraph } from '../ChalkGraph/ChalkGraph';

export const EmissionsSummary = () => {
    const { classes } = useEmissionsSummaryStyles();
    const { t } = useTranslation();

    return (
        <div className={classes.container}>
            <div className={classes.title}>
                <IconArrowEmission className={classes.emissionIcon} />
                <Typography variant="h3">{t('results.summary.emissions.title')}</Typography>
                <div className={classes.horizontalBar}></div>
            </div>
            <MineralFertilisationGraphs />
            <div className={classes.secondaryContainer}>
                <TillagePracticeGraph />
                <LivestockGraph />
            </div>
            <div className={classes.secondaryContainer}>
                <FuelConsumptionGraph />
                <ChalkGraph />
            </div>
            <IrrigationGraph />
        </div>
    );
};
