import { SeasonT } from '@shared/entities';
import { MenuItem, Select } from '@soil-capital/ui-kit';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export const ResultsEarningSelectSeason = ({
    selectedSeason,
    options,
    handleChange,
}: {
    selectedSeason?: SeasonT;
    options?: SeasonT[];
    handleChange: (event: SelectChangeEvent<unknown>) => void;
}) => {
    const { t } = useTranslation();

    return (
        <Select
            sx={{ width: '300px', maxHeight: '46px' }}
            placeholder={t('constants.select')}
            onChange={handleChange}
            value={selectedSeason?.id.toString()}
            renderValue={() => {
                if (selectedSeason?.baseline_farm_season_id) {
                    return t('results.earning.select-options.yoy-years');
                } else {
                    return t('results.earning.select-options.baseline-years');
                }
            }}
        >
            <MenuItem sx={{ display: 'none' }} value="" disabled>
                {t('constants.select')}
            </MenuItem>
            {options?.map((season) => {
                let slug = '';
                if (season?.baseline_farm_season_id) {
                    slug = t('results.earning.select-options.yoy-years');
                } else {
                    slug = t('results.earning.select-options.baseline-years');
                }
                return (
                    <MenuItem key={season.id} value={season.id.toString()}>
                        {slug}
                    </MenuItem>
                );
            })}
        </Select>
    );
};
