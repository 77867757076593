import { SeasonEndpointParamsT, SeasonT, TotalAreaCheckT } from './season.types';
import { EntityState } from '@reduxjs/toolkit';
import seasonCache from './season.cache';
import api from '@shared/api';

const url = (farmId?: number) => `/v3/farms/${farmId}/seasons`;

export const seasonApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getSeason: build.query<EntityState<SeasonT>, SeasonEndpointParamsT>({
            query: ({ farmId }) => ({
                url: url(farmId),
                method: 'GET',
            }),
            transformResponse: seasonCache.transformResponse,
            providesTags: ['Season'],
        }),
        checkTotalAreaWithPreviousSeason: build.query<TotalAreaCheckT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/check-total-area`,
                method: 'GET',
            }),
            providesTags: ['TotalArea'],
        }),
        cancelPacImport: build.mutation<void, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/cancel-pac-import`,
                method: 'POST',
            }),
            invalidatesTags: ['Field', 'FieldCrop', 'Progress', 'TotalArea', 'GeometryData'],
        }),
    }),
});

export default {
    useGet: seasonApiInstance.useGetSeasonQuery,
    useCheckTotalAreaWithPreviousSeason: seasonApiInstance.useCheckTotalAreaWithPreviousSeasonQuery,
    useLazyGet: seasonApiInstance.useLazyGetSeasonQuery,
    useCancelPacImport: seasonApiInstance.useCancelPacImportMutation,
};
