import partnerPortalApi from '@shared/api/partnerPortalApi';
import { MissingFieldsAndPermanentCropForHistorySeasonsResponseT } from './alarmingDataApi.types';

export const alarmingDataApi = partnerPortalApi.injectEndpoints({
    endpoints: (builder) => ({
        getMissingFieldsAndPermanentCropForHistorySeasons: builder.query<
            MissingFieldsAndPermanentCropForHistorySeasonsResponseT[] | null,
            { farmSeasonId: number }
        >({
            query: ({ farmSeasonId }) => ({
                url: `/private/alarming-data/${farmSeasonId}/MISSING_FIELDS_AND_PERMANENCE_DISCREPANCY`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetMissingFieldsAndPermanentCropForHistorySeasonsQuery } = alarmingDataApi;
