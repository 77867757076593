import { useTranslation } from 'react-i18next';
import { useChalkGraph } from './useChalkGraph.data';
import { useChalkGraphStyles } from './ChalkGraph.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconMineralFertilisation } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { ResultsCard } from '../../shared/ResultsCard/ResultsCard';

export const ChalkGraph = () => {
    const { t } = useTranslation();

    const { chalk, isLoadingChalk, hasNoData } = useChalkGraph();
    const { classes } = useChalkGraphStyles();

    return (
        <GraphContainer
            title={t('results.summary.emissions.chalk-title')}
            hasBorders
            className={classes.container}
            hasNoData={hasNoData}
            titleIcon={<IconMineralFertilisation />}
        >
            <div className={classes.secondaryContainer}>
                <ResultsCard
                    data={chalk}
                    isLoading={isLoadingChalk}
                    className={classes.cardContainer}
                    hasBorders={!hasNoData}
                />
                {!hasNoData && (
                    <Typography variant="body2" className={classes.textContainer}>
                        {t('results.summary.emissions.chalk-helper')}
                    </Typography>
                )}
            </div>
        </GraphContainer>
    );
};
