import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSequestrationSummaryStyle = makeStyles()((theme) => ({
    sequestration: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
        backgroundColor: theme.palette.success.light,
        padding: theme.spacing(3),
    },
    horizontalBar: {
        width: '100%',
        borderTop: '1px solid',
        borderColor: theme.palette.success.main,
        transform: 'translateY(2px)',
    },
    flexBox: { display: 'flex', alignItems: 'center', gap: theme.spacing(1) },
    grid: { display: 'grid', gridTemplateColumns: '50% 50%', gap: theme.spacing(3), marginRight: theme.spacing(3) },
    whiteBg: { backgroundColor: theme.palette.common.white },
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        gap: theme.spacing(3),
    },
}));
