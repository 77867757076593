import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import {
    IconArrowSequestration,
    IconArrowEmission,
    IconCropRotation,
    IconTractor,
    IconOrganicFertilisation,
    IconMineralFertilisation,
    IconPlant,
    IconFuel,
} from '@soil-capital/ui-kit/icons';
import { Accordion } from '@soil-capital/ui-kit';
import { useResultsFaqStyle } from './ResultsFaq.style';

export const ResultsFaq = () => {
    const { t } = useTranslation();
    const { classes } = useResultsFaqStyle();

    return (
        <div className={classes.accordionsContainer}>
            <Accordion
                Header={
                    <Accordion.Header className={classes.accordionHeader}>
                        {t('results.earning.faq.carbon-footprint.how-calculation-work.title')}
                    </Accordion.Header>
                }
                Content={
                    <Accordion.Content className={classes.content}>
                        <Typography variant="body2">
                            <Trans
                                t={t}
                                i18nKey={'results.earning.faq.carbon-footprint.how-calculation-work.description'}
                            />
                        </Typography>
                    </Accordion.Content>
                }
                className={classes.accordion}
            />
            <Accordion
                Header={
                    <Accordion.Header className={classes.accordionHeader}>
                        {t('results.earning.faq.certificates.how-calculation-work.title')}
                    </Accordion.Header>
                }
                Content={
                    <Accordion.Content className={classes.content}>
                        <Typography variant="body2">
                            <Trans
                                t={t}
                                i18nKey={'results.earning.faq.certificates.how-calculation-work.description'}
                            />
                        </Typography>
                    </Accordion.Content>
                }
                className={classes.accordion}
            />
            <Accordion
                Header={
                    <Accordion.Header className={classes.accordionHeader}>
                        {t('results.earning.faq.carbon-footprint.improvements.title')}
                    </Accordion.Header>
                }
                Content={
                    <Accordion.Content>
                        <div>
                            <Typography className={classes.description} variant="body2">
                                {t('results.earning.faq.carbon-footprint.improvements.description')}
                            </Typography>
                            <div className={classes.section}>
                                <Typography className={classes.sectionSubtitle} variant="h3">
                                    <IconArrowSequestration color="primary" />
                                    {t('results.earning.faq.carbon-footprint.improvements.sequestration.title')}
                                </Typography>
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconCropRotation />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.sequestration.crop-rotation.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.sequestration.crop-rotation.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconPlant />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.sequestration.cover-crops.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.sequestration.cover-crops.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconTractor />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.sequestration.soil-work.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.sequestration.soil-work.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconOrganicFertilisation />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.sequestration.organic-fertilisation.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.sequestration.organic-fertilisation.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                            </div>
                            <div className={classes.section}>
                                <Typography className={classes.sectionSubtitle} variant="h3">
                                    <IconArrowEmission color="primary" />
                                    {t('results.earning.faq.carbon-footprint.improvements.emission.title')}
                                </Typography>
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconMineralFertilisation />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.emission.mineral-fertilisation.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.emission.mineral-fertilisation.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                                <Accordion
                                    Header={
                                        <Accordion.Header>
                                            <IconFuel />
                                            <span>
                                                {t(
                                                    'results.earning.faq.carbon-footprint.improvements.emission.fuel.title',
                                                )}
                                            </span>
                                        </Accordion.Header>
                                    }
                                    Content={
                                        <Accordion.Content className={classes.content}>
                                            {t(
                                                'results.earning.faq.carbon-footprint.improvements.emission.fuel.description',
                                            )}
                                        </Accordion.Content>
                                    }
                                />
                            </div>
                        </div>
                    </Accordion.Content>
                }
                className={classes.accordion}
            />
        </div>
    );
};
