import { useGetFuelConsumptionQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useFuelConsumptionGraph = () => {
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);

    const {
        data: fuelConsumptionData,
        isLoading: isLoadingFuelConsumption,
        isFetching: isFetchingFuelConsumption,
    } = useGetFuelConsumptionQuery({
        farmSeasonId: selectedSeasonId,
    });

    const hasNoData = useMemo(() => !fuelConsumptionData?.total_energy, [fuelConsumptionData]);

    return {
        hasNoData,
        fuelConsumptionData,
        isLoadingFuelConsumption: isLoadingFuelConsumption || isFetchingFuelConsumption,
    };
};
