import { makeStyles } from '@soil-capital/ui-kit/style';

export const useProblematicFieldListStyles = makeStyles()((theme) => ({
    container: {
        outline: `1px solid ${theme.palette.warning.main}`,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadiusM,
    },
    flexbox: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    title: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(2),
    },
    fullWidth: { width: '100%', paddingTop: theme.spacing(2) },
}));
