import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { currencyIsoCodeSlugs } from '@shared/constants/currencyIsoCodeSlugs';
import { PRICING } from '@shared/constants/pricing';
import { sumPercentageDiscounts } from '@shared/utils/sumPercentageDiscounts';
import { useNavigate } from 'react-router-dom';
import { PaymentStepTimelinePropsT } from '../PaymentStepTimeline.types';

export const usePaymentStepTimelineConditionsLogic = ({ payment, status, farmId }: PaymentStepTimelinePropsT) => {
    const { data: farmPaymentStatus, isLoading } = useGetFarmPaymentStatusQuery({ farmId });
    const { currentSeasonId } = useCurrentSeasonId();
    const navigate = useNavigate();

    const redirectToPaymentFlow = () => navigate(`/${currentSeasonId}/payment/pricing`);

    const floorPrice = payment.currency.iso_code === currencyIsoCodeSlugs.EUR ? PRICING.floorEUR : PRICING.floorUK;
    const ceilingPrice =
        payment.currency.iso_code === currencyIsoCodeSlugs.EUR ? PRICING.ceilingEUR : PRICING.ceilingUK;
    const currency = payment.currency.symbol;
    const isBaselinePayment = payment.program_year === 0;
    const isFuturePayment = status === 'disabled';
    const isFailedPayment = payment.status === 'payment_failed';
    const isPendingPayment = payment.status === 'pending';
    const isActivePayment = !payment.paid_at;
    const isNetOffPayment = payment.product_plan === 'NETOFF';
    const currentRenewalDiscount = payment.discounts[0]?.value;
    const totalDiscount = payment.discounts.length > 0 ? sumPercentageDiscounts(payment.discounts) : null;
    const canRenew = farmPaymentStatus?.status === 'to_be_renewed';
    const isDiscountedPaidPayment = !!(
        payment.discounts.length > 0 &&
        payment.paid_at &&
        !payment.is_floor &&
        !payment.is_ceiling
    );
    const isDiscountedPayment = !!(
        currentRenewalDiscount &&
        !payment.paid_at &&
        !isBaselinePayment &&
        !payment.is_floor &&
        !payment.is_ceiling
    );
    const isDiscountedFloorOrCeilingPaidPayment = !!(
        payment.discounts.length > 0 &&
        payment.paid_at &&
        (payment.is_floor || payment.is_ceiling)
    );
    const isDiscountedFloorOrCeilingPayment = !!(
        currentRenewalDiscount &&
        !payment.paid_at &&
        !isBaselinePayment &&
        (payment.is_floor || payment.is_ceiling)
    );
    const isFloorOrCeilingPaidPayment = !!(
        payment.discounts.length === 0 &&
        payment.paid_at &&
        (payment.is_floor || payment.is_ceiling)
    );
    const isFloorOrCeilingPayment =
        !currentRenewalDiscount && !payment.paid_at && !isBaselinePayment && (payment.is_floor || payment.is_ceiling);
    // this is only used to display a crossed-out price only for renewals not paid yet
    const discountedPrice =
        !payment.paid_at && currentRenewalDiscount && !payment.deferred_payment && !isPendingPayment && !isFailedPayment
            ? payment.total_excluding_tax
            : null;
    const hasDiscountDeadline = payment.discounts.length > 0 && payment.discounts[0].end_date !== null;

    const paymentContext = {
        floorPrice,
        ceilingPrice,
        currency,
        isBaselinePayment,
        isFuturePayment,
        isFailedPayment,
        isPendingPayment,
        isActivePayment,
        isNetOffPayment,
        currentRenewalDiscount,
        totalDiscount,
        canRenew,
        isDiscountedPaidPayment,
        isDiscountedPayment,
        isDiscountedFloorOrCeilingPaidPayment,
        isDiscountedFloorOrCeilingPayment,
        isFloorOrCeilingPaidPayment,
        isFloorOrCeilingPayment,
        discountedPrice,
        hasDiscountDeadline,
    };

    return {
        isLoading,
        redirectToPaymentFlow,
        paymentContext,
    };
};
