import { Table } from '@soil-capital/ui-kit/components';
import { useIrrigationGraph } from './useIrrigationGraph.data';
import { useIrrigationGraphStyles } from './IrrigationGraph.style';
import { useTranslation } from 'react-i18next';
import { IconIrrigation } from '@soil-capital/ui-kit/icons';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { ResultsCard } from '../../shared/ResultsCard/ResultsCard';
import { TableSkeleton } from '../../shared/TableSkeleton/TableSkeleton';

export const IrrigationGraph = () => {
    const { percentageIrrigatedFormatted, formattedIrrigationPerCrop, isLoading, hasNoData } = useIrrigationGraph();
    const { t } = useTranslation();
    const { classes } = useIrrigationGraphStyles();
    return (
        <GraphContainer
            title={t('results.summary.emissions.irrigation-title')}
            hasNoData={hasNoData}
            orientation={'vertical'}
            titleIcon={<IconIrrigation />}
        >
            <div className={classes.container}>
                <ResultsCard
                    data={percentageIrrigatedFormatted}
                    className={classes.cardContainer}
                    isLoading={isLoading}
                    hasBorders={true}
                />
                {isLoading ? (
                    <TableSkeleton />
                ) : (
                    <div className={classes.tableContainer}>
                        <Table
                            {...formattedIrrigationPerCrop}
                            seeLessLabel={t('constants.see-less')}
                            seeMoreLabel={t('constants.see-more')}
                            seeNextLabel={t('constants.see-next')}
                            stickyFirstColumn
                        />
                    </div>
                )}
            </div>
        </GraphContainer>
    );
};
