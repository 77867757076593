import { makeStyles } from '@soil-capital/ui-kit/style';

export const useIrrigationGraphStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: theme.spacing(3),
        width: '100%',
    },
    cardContainer: {
        width: 'auto',
        minWidth: '20%',
        whiteSpace: 'nowrap',
    },
    centeredContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    tableContainer: {
        width: '100%',
    },
}));
