import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacImportHeaderStyles = makeStyles()((theme) => ({
    harvest_year: {
        color: theme.palette.success.main,
    },
    helpButton: {
        marginTop: theme.spacing(2),
    },
    uploadIcon: {
        width: 16,
    },
    alertIcon: {
        width: 35,
    },
    caption: { paddingTop: theme.spacing(3), display: 'inline-block' },
    invalidFieldsTextContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        borderRadius: 10,
        border: `1px solid ${theme.palette.error.main}`,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(0.5),
        gap: theme.spacing(1),
    },
    deleteInvalidFieldsButton: {
        marginTop: theme.spacing(2),
    },
    trashIcon: {
        width: 25,
    },
    pacImportContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
    iconInfo: {
        cursor: 'pointer',
    },
}));
