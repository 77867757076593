import { FormRow } from '@shared/components';
import { NumberInput } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useIrrigationFormStyle } from './useIrrigationForm.style';
import z from 'zod';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { SelectOption, SelectOptionItemT } from '@shared/components/SelectOption/SelectOption';
import { useIrrigationFormLogic } from './useIrrigationFormlogic';

const irrigationFormSchema = z.object({
    irrigationMethodId: z.coerce.number().nullable(),
    irrigationPowerSourceId: z.coerce.number().nullable(),
    irrigationWaterSourceId: z.coerce.number().nullable(),
    pumpingDepth: z.coerce.number().nonnegative().nullable(),
    horizontalDistance: z.coerce.number().nonnegative().nullable(),
    waterAmount: z.coerce.number().nonnegative().nullable(),
});
export type IrrigationFormValuesT = z.infer<typeof irrigationFormSchema>;

export type FormStaticDataT = {
    methodsList: SelectOptionItemT[];
    powerSourceList: SelectOptionItemT[];
    waterSourceList: SelectOptionItemT[];
};

export const IrrigationForm = ({
    isReadOnly,
    formStaticData: { methodsList, powerSourceList, waterSourceList },
    initialFormValues,
    onFormValuesChange,
}: {
    isReadOnly: boolean;
    formStaticData: FormStaticDataT;
    initialFormValues: IrrigationFormValuesT | null;
    onFormValuesChange: (formValues: IrrigationFormValuesT) => void;
}) => {
    const { t } = useTranslation();
    const { classes } = useIrrigationFormStyle();
    const { handleFormValuesChange, values } = useIrrigationFormLogic({ initialFormValues, onFormValuesChange });

    return (
        <div className={classes.form}>
            <FormRow data-testid="method-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.method-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.method-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.method-tooltip-description"
                />
                <SelectOption
                    onChange={(v) => handleFormValuesChange('irrigationMethodId', v)}
                    options={methodsList}
                    value={values?.irrigationMethodId}
                    disabled={isReadOnly}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.method-placeholder')}
                />
            </FormRow>

            <FormRow data-testid="water-amount-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.water-amount-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.water-amount-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.water-amount-tooltip-description"
                />
                <NumberInput
                    onChange={(v) => handleFormValuesChange('waterAmount', v)}
                    value={values?.waterAmount}
                    min={0}
                    max={9999999}
                    allowedDecimals={0}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.water-amount-placeholder')}
                    suffix={t('constants.unit.milimeters')}
                    disabled={isReadOnly}
                    style={{ width: '250px' }}
                />
            </FormRow>

            <FormRow data-testid="water-source-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.water-source-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.water-source-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.water-source-tooltip-description"
                />
                <SelectOption
                    onChange={(v) => handleFormValuesChange('irrigationWaterSourceId', v)}
                    options={waterSourceList}
                    value={values?.irrigationWaterSourceId}
                    disabled={isReadOnly}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.water-source-placeholder')}
                />
            </FormRow>

            <FormRow data-testid="power-source-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.power-source-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.power-source-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.power-source-tooltip-description"
                />
                <SelectOption
                    onChange={(v) => handleFormValuesChange('irrigationPowerSourceId', v)}
                    options={powerSourceList}
                    value={values?.irrigationPowerSourceId}
                    disabled={isReadOnly}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.power-source-placeholder')}
                />
            </FormRow>

            <FormRow data-testid="horizontal-distance-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.horizontal-distance-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.horizontal-distance-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.horizontal-distance-tooltip-description"
                />
                <NumberInput
                    onChange={(v) => handleFormValuesChange('horizontalDistance', v)}
                    value={values?.horizontalDistance}
                    min={0}
                    max={9999999}
                    disabled={isReadOnly}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.horizontal-distance-placeholder')}
                    suffix={t('constants.unit.meters')}
                    style={{ width: '250px' }}
                />
            </FormRow>

            <FormRow data-testid="pumping-depth-question">
                <FormQuestion
                    question={t('encoding-irrigation.irrigation-form-action-page.pumping-depth-question')}
                    tooltipTextKey="encoding-irrigation.irrigation-form-action-page.pumping-depth-tooltip-title"
                    tooltipTitleKey="encoding-irrigation.irrigation-form-action-page.pumping-depth-tooltip-description"
                />
                <NumberInput
                    onChange={(v) => handleFormValuesChange('pumpingDepth', v)}
                    value={values?.pumpingDepth}
                    min={0}
                    max={999}
                    suffix={t('constants.unit.meters')}
                    placeholder={t('encoding-irrigation.irrigation-form-action-page.pumping-depth-placeholder')}
                    disabled={isReadOnly}
                    style={{ width: '250px' }}
                />
            </FormRow>
        </div>
    );
};
