import { makeStyles } from '@soil-capital/ui-kit/style';

export const useTillagePracticeGraphStyles = makeStyles()(() => ({
    container: {
        width: '50%',
        gap: 0,
        paddingBottom: 0,
    },
    graphContainer: {
        height: '412px',
        width: '100%',
    },
}));
