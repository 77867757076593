import { Button } from '@soil-capital/ui-kit/components';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useResultsEarningNoData } from './useResultsEarningNoData';
import { useResultsEarningNoDataStyle } from './ResultsEarningNoData.style';

export const ResultsEarningNoData = () => {
    const { t } = useTranslation();
    const { isEncodingStarted, handleContinueEncoding, handleStartEncoding } = useResultsEarningNoData();
    const { classes } = useResultsEarningNoDataStyle();
    return (
        <div className={classes.container}>
            <img className={classes.image} src="/assets/images/no_data.svg" />
            <Typography>{t('results.earning.encoding-pending.title')}</Typography>
            <Typography variant="body2" className={classes.text}>
                {isEncodingStarted
                    ? t('results.earning.encoding-pending.ongoing')
                    : t('results.earning.encoding-pending.start')}
            </Typography>
            <Button
                variant="contained"
                endIcon={<IconArrowRight />}
                onClick={isEncodingStarted ? handleContinueEncoding : handleStartEncoding}
            >
                {isEncodingStarted
                    ? t('results.earning.encoding-pending.ongoing.cta')
                    : t('results.earning.encoding-pending.start.cta')}
            </Button>
        </div>
    );
};
