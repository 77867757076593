import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsEarningStyle = makeStyles()(() => ({
    header: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
}));
