import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import entities from '@shared/entities';
import { SharedStateT } from '@shared/store';
import { useSelector } from 'react-redux';

export const useGetSelectedHarvestYear = () => {
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const { currentFarm, currentFarmLoading } = useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });

    return {
        selectedHarvestYear: seasonState.getById(selectedSeasonId)?.harvest_year,
        selectedHarvestYearLoading: currentFarmLoading || seasonState.isLoading,
    };
};
