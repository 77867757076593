import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useUpdateFarmSeasonField } from '@modules/encoding/shared/hooks/useUpdateFarmSeasonField';
import { useRef, useCallback } from 'react';
import { calculateAreaProperties } from '@shared/utils/calculateAreaProperties';
import { useTranslation } from 'react-i18next';
import { FieldT } from '@shared/entities/field/field.types';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { GeometryDataT } from '@shared/entities';
import fieldApi from '@shared/entities/field/field.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const EDITED_FIELD = {
    NAME: 'edited_field_name',
    AREA: 'edited_area',
} as const;

export const useEditedFieldModalFormLogic = ({
    updatedField,
    onClose,
    open,
}: {
    updatedField: FieldT | GeometryDataT;
    onClose: () => void;
    open: boolean;
}) => {
    const { t } = useTranslation();
    const shouldFocus = useRef(true);
    const { treshold, maxArea, minArea } = calculateAreaProperties(updatedField.original_area);
    const { currentSeasonId } = useCurrentSeasonId();
    const defaultValues = {
        [EDITED_FIELD.NAME]: updatedField.name,
        [EDITED_FIELD.AREA]: Number(updatedField.original_area.toFixed(2)),
    };

    const schema = z.object({
        [EDITED_FIELD.NAME]: z.string().min(1, t('encoding.rotation.field-creation-modal.error-name')),
        [EDITED_FIELD.AREA]: z
            .number()
            .min(
                Number(minArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-under-treshold', { treshold }),
            )
            .max(
                Number(maxArea.toFixed(2)),
                t('encoding.rotation.field-creation-modal.error-over-treshold', { treshold }),
            ),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });
    const { updateField, isLoading } = useUpdateFarmSeasonField();
    const [createCorrectedInvalidField, { isLoading: isLoadingCorrectedInvalidField }] =
        fieldApi.useCreateCorrectedInvalidField();
    const {
        reset,
        handleSubmit,
        formState: { isValid, errors },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        const name = data[EDITED_FIELD.NAME];
        const userArea =
            data[EDITED_FIELD.AREA] !== Number(updatedField.original_area.toFixed(2))
                ? data[EDITED_FIELD.AREA]
                : updatedField.original_area;

        let response;

        if ('properties' in updatedField) {
            response = await createCorrectedInvalidField({
                farmSeasonId: currentSeasonId,
                body: {
                    id: updatedField.id,
                    geometry_data_id: updatedField.geometry_data_id,
                    name: name,
                    area: userArea,
                    original_area: updatedField.original_area,
                    polygon: updatedField.polygon,
                    properties: updatedField.properties,
                },
            });
        } else {
            response = await updateField({
                id: updatedField.id,
                name: name,
                original_area: updatedField.original_area,
                area: userArea,
                polygon_geometry: updatedField.polygon.geometry,
            });
        }

        response && 'data' in response
            ? enqueueSnackbar(t('encoding-rotation.pac-file.update-field-toast'), { variant: 'success' })
            : enqueueSnackbar(t('constants.server-error'), { variant: 'error' });
    });

    const onConfirm = async () => {
        await onSubmit();
        if (isValid) {
            onClose();
        }
    };

    const inputRef = useCallback(
        (node: HTMLInputElement) => {
            if (node && open && shouldFocus.current) {
                node.focus();
                shouldFocus.current = false;
            }
        },
        [open],
    );

    useEffect(() => {
        reset({
            [EDITED_FIELD.NAME]: updatedField.name,
            [EDITED_FIELD.AREA]: Number(updatedField.original_area.toFixed(2)),
        });
    }, [updatedField, reset]);

    return {
        methods,
        onConfirm,
        isEditingField: isLoading || isLoadingCorrectedInvalidField,
        errors,
        treshold,
        inputRef,
    };
};
