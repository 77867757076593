import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useIsEncodingDone } from '@shared/hooks/useIsEncodingDone';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useNavigate } from 'react-router-dom';

export const useResultsEarningNoData = () => {
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();
    const { isLoading: isLoadingIsEncodingDone, isEncodingStarted } = useIsEncodingDone();

    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const pathToRedirectTo =
        nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
            ? getRouteMatchingStep(nextStepToComplete?.slug)
            : encodingRoutesConfig.PREPARATION;
    const handleContinueEncoding = () => navigate(`/${currentSeasonId}/${pathToRedirectTo}`);
    const handleStartEncoding = () => navigate(`/${currentSeasonId}/${encodingRoutesConfig.PREPARATION}`);

    const isLoading = isLoadingIsEncodingDone;

    return {
        isLoading,
        isEncodingStarted,
        handleContinueEncoding,
        handleStartEncoding,
    };
};
