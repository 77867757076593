import { IconMineralFertilisation } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';
import { useMineralFertilisationGraphsStyles } from './MineralFertilisationGraphs.style';
import { useMineralFertilisationGraphs } from './useMineralFertilisationGraphs.data';
import { Table } from '@soil-capital/ui-kit/components';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { TableSkeleton } from '../../shared/TableSkeleton/TableSkeleton';

export const MineralFertilisationGraphs = ({}) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertilisationGraphsStyles();
    const { nitrogenGraphRef, mineralProductsGraphRef, isLoading, formattedFertilisersPerCropData, hasNoData } =
        useMineralFertilisationGraphs();

    return (
        <GraphContainer
            title={t('results.summary.emissions.mineral-fertilisation-title')}
            titleIcon={<IconMineralFertilisation />}
            orientation="vertical"
            hasNoData={hasNoData}
            hasBorders
        >
            <div className={classes.verticalContainer}>
                <div className={classes.horizontalContainer}>
                    <GraphContainer
                        title={t('results.summary.emissions.nitrogen-consommation')}
                        isLoading={isLoading}
                        hasBorders
                        className={classes.noPadding}
                    >
                        <div ref={nitrogenGraphRef} className={classes.graphContainer} />
                    </GraphContainer>
                    <GraphContainer
                        title={t('results.summary.emissions.mineral-products-consommation')}
                        hasBorders
                        isLoading={isLoading}
                        className={classes.noPadding}
                    >
                        <div ref={mineralProductsGraphRef} className={classes.graphContainer} />
                    </GraphContainer>
                </div>
                <div className={classes.tableContainer}>
                    {isLoading ? (
                        <TableSkeleton />
                    ) : (
                        <Table
                            {...formattedFertilisersPerCropData}
                            seeLessLabel={t('constants.see-less')}
                            seeMoreLabel={t('constants.see-more')}
                            seeNextLabel={t('constants.see-next')}
                            stickyFirstColumn
                        />
                    )}
                </div>
            </div>
        </GraphContainer>
    );
};
