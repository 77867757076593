import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import { encodingShared } from '@modules/encoding/shared';
import entities, { OperationT } from '@shared/entities';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

export const useOperationListOrder = () => {
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['operationForm']>();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: currentSeasonId,
    });
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const operationProgressDetails = progressState
        .getBySlugAndFieldCropId(PROGRESS_SLUGS.TECHNICAL_ITINERARY, Number(fieldCropId))
        ?.filter((progressDetail) => progressDetail.farm_season_field_crop_operation_id !== null);

    const emptyFormOperationTypeIds = staticOperationTypeState.list
        .filter((operationType) => {
            return (
                operationType.slug === OPERATION_TYPE_SLUGS.SOIL_WORK_ROLLER ||
                operationType.slug === OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION ||
                operationType.slug === OPERATION_TYPE_SLUGS.MAINTENANCE_SHREDDING
            );
        })
        .map((operationType) => operationType.id);

    const sortedOperationList = operationProgressDetails
        ? operationProgressDetails
              ?.map((progressDetail) => {
                  return operationState.list.find(
                      (operation) =>
                          operation.farm_season_field_crop_operation_id ===
                          progressDetail.farm_season_field_crop_operation_id,
                  );
              })
              .filter(
                  (operation): operation is OperationT =>
                      operation !== undefined && !emptyFormOperationTypeIds.includes(operation.operation_type_id),
              )
        : [];

    const getOperationIndex = (operationId: number) => sortedOperationList.findIndex((op) => op.id === operationId);

    const isLastOperation = (operationId: number) => {
        const index = getOperationIndex(operationId);
        return index === sortedOperationList.length - 1;
    };

    const getNextOperation = (operationId?: number) => {
        const index = operationId ? getOperationIndex(operationId) : -1;
        return sortedOperationList[index + 1];
    };

    const getNextNotDoneOperation = (operationId?: number) => {
        const index = operationId ? getOperationIndex(operationId) : 0;
        const nextOperations = sortedOperationList.slice(index);

        return nextOperations.find(
            (operation) =>
                operationProgressDetails?.find(
                    (progressDetail) =>
                        operation.farm_season_field_crop_operation_id ===
                        progressDetail.farm_season_field_crop_operation_id,
                )?.is_done === false,
        );
    };

    const getPreviousOperation = (operationId?: number) => {
        const index = operationId ? getOperationIndex(operationId) : -1;
        return sortedOperationList[index - 1];
    };

    const areAllOperationsDone = () => {
        return sortedOperationList.every(
            (operation) =>
                operationProgressDetails?.find(
                    (progressDetail) =>
                        operation.farm_season_field_crop_operation_id ===
                        progressDetail.farm_season_field_crop_operation_id,
                )?.is_done === true,
        );
    };

    return {
        isLastOperation,
        getNextOperation,
        getNextNotDoneOperation,
        getPreviousOperation,
        areAllOperationsDone,
        sortedOperations: sortedOperationList,
    };
};
