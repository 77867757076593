import { IconCreditCardEdit, IconPen } from '@soil-capital/ui-kit/icons';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import useBillingInfoCardStyle from './useBillingInfoCard.style';
import { useTranslation } from 'react-i18next';
import { Button } from '@soil-capital/ui-kit/components';
import useBillingInfoCardModal from './useBillingInfoCardModal';
import { BillingInfoModalForm } from '../BillingInfoModalForm/BillingInfoModalForm';
import { useGetUserCompanyQuery } from '@modules/billing/api/userCompanyApi';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { auth } from '@modules/auth';

export const BillingInfoCard = ({ farmId }: { farmId: number }) => {
    const { classes } = useBillingInfoCardStyle();
    const { t } = useTranslation();
    const { billingInfoModalOpen, onBillingInfoModalClose, onBillingInfoModalOpen } = useBillingInfoCardModal();
    const isReadOnly = !auth.usePermission('canEditBillingPage');
    const {
        data: billingInfo,
        isLoading,
        refetch,
    } = useGetUserCompanyQuery({
        farmId,
    });

    return isLoading || !billingInfo ? (
        <Grid>
            <Skeleton className={classes.skeleton} data-testid="skeleton" />
        </Grid>
    ) : (
        <>
            <div className={classes.container}>
                <div className={classes.titleContainer}>
                    <IconCreditCardEdit />
                    <Typography className={classes.title} variant="h2">
                        {t('billing.subscription.billing-info.title')}
                    </Typography>
                    <Button className={classes.penIcon} onClick={onBillingInfoModalOpen} disabled={isReadOnly}>
                        <IconPen />
                    </Button>
                </div>
                <div className={classes.addressDetails}>
                    <Typography variant="body">{billingInfo?.name}</Typography>
                    <Typography variant="body2" color="dark">
                        {billingInfo?.address}
                    </Typography>
                    <Typography variant="body2" color="dark">
                        {billingInfo?.city}
                    </Typography>
                    <Typography variant="body2" color="dark">
                        {billingInfo?.postal_code}
                    </Typography>
                    <Typography variant="body2" color="dark">
                        {t(`${billingInfo?.country?.translation_slug}`)}
                    </Typography>
                </div>
                <div className={classes.billingDetails}>
                    <div className={classes.billingGroup}>
                        <Typography variant="body2">{`${t('billing.subscription.billing-info.VAT')} : `}</Typography>
                        <Typography variant="body" color="dark" sx={{ paddingLeft: '4px' }}>
                            {billingInfo?.tax_id}
                        </Typography>
                    </div>
                    {billingInfo?.country?.iso_code === 'FR' && (
                        <div className={classes.billingGroup}>
                            <Typography variant="body2">{`${t(
                                'billing.subscription.billing-info.siret',
                            )} : `}</Typography>
                            <Typography variant="body" color="dark" sx={{ paddingLeft: '4px' }}>
                                {billingInfo?.siret_id}
                            </Typography>
                        </div>
                    )}
                    {billingInfo?.iban && (
                        <div className={classes.billingGroup}>
                            <Typography variant="body2">{`${t(
                                'billing.subscription.billing-info.IBAN',
                            )} : `}</Typography>
                            <Typography variant="body" color="dark" sx={{ paddingLeft: '4px' }}>
                                {billingInfo?.iban}
                            </Typography>
                        </div>
                    )}
                </div>
            </div>
            <BillingInfoModalForm
                open={billingInfoModalOpen}
                onClose={onBillingInfoModalClose}
                userCompany={billingInfo}
                refetch={refetch}
            />
        </>
    );
};
