import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { useResultsEarningStyle } from '@modules/results/pages/ResultsEarning/ResultsEarning.style';
import { useResultsEarning } from '@modules/results/pages/ResultsEarning/useResultsEarning';
import { ResultsEarningSkeleton } from '@modules/results/components/ResultsEarning/ResultsEarningSkeleton/ResultsEarningSkeleton';
import { ResultsEarningNoData } from '@modules/results/components/ResultsEarning/ResultsEarningNoData/ResultsEarningNoData';
import { ResultsEarningSelectSeason } from '@modules/results/components/ResultsEarning/ResultsEarningSelectSeason/ResultsEarningSelectSeason';
import { ResultsEarningContentPage } from '@modules/results/components/ResultsEarning/ResultsEarningContentPage/ResultsEarningContentPage';
import { ResultsEarningContentPageTeaser } from '@modules/results/components/ResultsEarning/ResultsEarningContentPage/ResultsEarningContentPageTeaser';

export const ResultsEarning = () => {
    const { classes } = useResultsEarningStyle();
    const {
        results,
        harvestYear,
        isNetEmitter,
        isAuditDone,
        isLoading,
        hasAccessToResults,
        isEncodingDone,
        handleHarvestChange,
        selectOptions,
        selectedSeason,
    } = useResultsEarning();

    if (isLoading || !selectedSeason) {
        return <ResultsEarningSkeleton />;
    }

    return (
        <PageWithHeader
            header={
                <div className={classes.header}>
                    <ResultsEarningSelectSeason
                        handleChange={handleHarvestChange}
                        selectedSeason={selectedSeason}
                        options={selectOptions}
                    />
                </div>
            }
            content={
                !isEncodingDone ? (
                    <ResultsEarningNoData />
                ) : results && harvestYear && hasAccessToResults && isNetEmitter !== null ? (
                    <ResultsEarningContentPage
                        harvestYear={harvestYear}
                        isAuditDone={isAuditDone}
                        isNetEmitter={isNetEmitter}
                        results={results}
                    />
                ) : (
                    <ResultsEarningContentPageTeaser />
                )
            }
        />
    );
};
