import { EmissionsSummary } from '@modules/results/components/ResultsSummary/EmissionsSummary/EmissionsSummary';
import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { Button, Select } from '@soil-capital/ui-kit/components';
import { IconArrowLeft, IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useResultsSummaryStyle } from './ResultsSummary.style';
import { MenuItem, Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useResultsSummaryLogic } from './useResultsSummary.logic';
import { SummarySkeleton } from '@modules/results/components/ResultsSummary/SummarySkeleton/SummarySkeleton';
import { CropRotationGraph } from '@modules/results/components/ResultsSummary/CropRotationGraph/CropRotationGraph';
import { SequestrationSummary } from '@modules/results/components/ResultsSummary/SequestrationSummary/SequestrationSummary';

export const ResultsSummary = () => {
    const { t } = useTranslation();
    const { classes } = useResultsSummaryStyle();
    const {
        selectedSeasonId,
        harvestOptions,
        handleHarvestChange,
        handleBackClicked,
        handleContinueEncoding,
        handleStartEncoding,
        isLoading,
        isEncodingDone,
        isEncodingStarted,
    } = useResultsSummaryLogic();

    if (isLoading) {
        return <SummarySkeleton />;
    }

    return (
        <PageWithHeader
            header={
                <div className={classes.flexBox}>
                    <Button onClick={handleBackClicked} size="medium" startIcon={<IconArrowLeft />} variant="text">
                        {t('constants.back')}
                    </Button>
                    <Select
                        value={selectedSeasonId}
                        onChange={handleHarvestChange}
                        sx={{ width: '240px', maxHeight: '46px' }}
                    >
                        {harvestOptions.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {`${t(
                                    (option?.carbon_programme_year ?? 0) < 0
                                        ? 'constants.historic-harvest-year'
                                        : 'constants.harvest-year',
                                    { harvestYear: option.harvest_year },
                                )}`}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            }
            content={
                isEncodingDone ? (
                    <>
                        <div className={classes.title}>
                            <Typography variant="h1">{t('results.summary.title')}</Typography>
                        </div>
                        <CropRotationGraph />
                        <div className={classes.textContainer}>
                            <Typography variant="h2">{t('results.summary.description-title')}</Typography>
                            <Typography variant="body2">
                                <Trans i18nKey="results.summary.description-subtitle" variant="body2" />
                            </Typography>
                        </div>
                        <SequestrationSummary />
                        <EmissionsSummary />
                    </>
                ) : (
                    <div className={classes.container}>
                        <img className={classes.image} src="/assets/images/no_data.svg" />
                        <Typography>{t('results.summary.encoding-pending.title')}</Typography>
                        <Typography variant="body2" className={classes.text}>
                            {isEncodingStarted
                                ? t('results.summary.encoding-pending.ongoing')
                                : t('results.summary.encoding-pending.start')}
                        </Typography>
                        <Button
                            variant="contained"
                            endIcon={<IconArrowRight />}
                            onClick={isEncodingStarted ? handleContinueEncoding : handleStartEncoding}
                        >
                            {isEncodingStarted
                                ? t('results.summary.encoding-pending.ongoing.cta')
                                : t('results.summary.encoding-pending.start.cta')}
                        </Button>
                    </div>
                )
            }
            contentClasses={classes.content}
        />
    );
};
