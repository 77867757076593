import { useMap } from '../hook/useMapHook';
import { createContext, useRef, useState } from 'react';
import { MapContextT } from '../types/mapTypes';
import { FieldT } from '@shared/entities';
import { FeatureCollection } from '@turf/helpers';
import { GeometryDataT } from '@shared/entities/geometryData/geometryData.types';

interface MapProviderProps {
    children: React.ReactNode;
}

export const MapContext = createContext<MapContextT | null>(null);

export const MapProvider = ({ children }: MapProviderProps) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);
    const [fields, setFields] = useState<FieldT[]>([]);
    const [invalidFields, setInvalidFields] = useState<GeometryDataT[]>([]);
    const [selectedFields, setSelectedFields] = useState<FieldT[]>([]);
    const [permanentFields, setPermanentFields] = useState<FieldT[]>([]);
    const [drawMode, setDrawMode] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [splitMode, setSplitMode] = useState<boolean>(false);
    const [mergeMode, setMergeMode] = useState<boolean>(false);
    const [farmBoundaries, setFarmBoundaries] = useState<FeatureCollection | null>(null);
    const { recenterMapOnPolygons, centerMapOnCoordinates, calculateCentroid, zoomIn, zoomOut } = useMap(
        map,
        mapContainerRef,
    );

    return (
        <MapContext.Provider
            value={{
                map,
                mapContainerRef,
                recenterMapOnPolygons,
                centerMapOnCoordinates,
                calculateCentroid,
                zoomIn,
                zoomOut,
                setMap,
                fields,
                setFields,
                invalidFields,
                setInvalidFields,
                permanentFields,
                setPermanentFields,
                selectedFields,
                setSelectedFields,
                drawMode,
                setDrawMode,
                editMode,
                setEditMode,
                splitMode,
                setSplitMode,
                mergeMode,
                setMergeMode,
                farmBoundaries,
                setFarmBoundaries,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
