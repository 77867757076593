export type UserT = {
    first_name: string;
    last_name: string;
    email: string;
    id: number;
    language_id: number;
    hubspot_id: number;
    role_id: number | null;
    role: RoleT | null;
};

type RoleT = {
    id: number;
    name: (typeof STATIC_ROLE_SLUGS)[keyof typeof STATIC_ROLE_SLUGS];
    type: 'FARMER_PORTAL' | 'PARTNER_PORTAL';
};

type _AuthRespT = {
    access_token: string;
    expires_in: number;
    user: UserT;

    user_infos: null | {
        // the latest season that contains a season_id (= opened and ready to encode)
        latest_active_season: number | null;
        // the season_id of the season created during the onboarding
        latest_farm_season: number | null;
        // dndc or cft depending on the carbon_programme.cft_versionESx3g3@6*tF7hV@hk!7!4Hjd
        model_type: 'cft' | 'dndc' | null;
        redirect_url: string | null;
    };
};

export type AuthorizeAuth0RespT = _AuthRespT;

export type AuthorizeAuth0ReqT = {
    /** Auth0 token */
    token: string;
};

export type ChangeLanguageRespT = {
    language_id: number;
};

export type ChangeLanguageReqT = {
    /** Language id */
    id: number;
};

export type GetAuthUserRespT = { user: UserT };
export type GetAuthUserReqT = void;

export type LoginRespT = _AuthRespT;
export type LoginReqT = {
    email: string;
    password: string;
};

export type LogoutRespT = void;

export type LogoutReqT = void;

export type RegisterRespT = _AuthRespT & { message?: string; code?: string; error?: string; status?: number };

export type RegisterReqT = {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    language_id?: number;
    token?: string;
    invitation_id?: number;
    referral_code?: string;
};

export type ResetPasswordRespT = { message: 'Password successfully reset' };
export type ResetPasswordReqT = {
    token: string;
    email: string;
    password: string;
    password_confirmation: string;
};

export type SendResetPasswordLinkRespT = { message: 'Reset password email sent' };
export type SendResetPasswordLinkReqT = {
    email: string;
};

export type ValidateCookieRespT = _AuthRespT;
export type ValidateCookieReqT = void;

export const STATIC_ROLE_SLUGS = {
    FARMER: 'FARMER',
    AGRONOMIST: 'AGRONOMIST',
    EXTERNAL_AGRONOMIST: 'EXTERNAL_AGRONOMIST',
} as const;

export type GetPermissionRespT = {
    permissions: [
        /* ---------------------- canEditFarmSeason scopes ---------------------- */
        'FP_CAN_EDIT_ALL_FARM-SEASON',
        'FP_CAN_EDIT_SELF_FARM-SEASON',
        'FP_CAN_EDIT_ASSIGNED-ASSOCIATE_FARM-SEASON',
        'FP_CAN_EDIT_ASSIGNED-PARTNER_FARM-SEASON',
        /* ---------------------- canFinalizeFarmSeason scopes ---------------------- */
        'FP_CAN_FINALIZE_ALL_FARM-SEASON',
        'FP_CAN_FINALIZE_ASSIGNED-ASSOCIATE_FARM-SEASON',
        'FP_CAN_FINALIZE_ASSIGNED-PARTNER_FARM-SEASON',
        'FP_CAN_FINALIZE_PARTNER_FARM-SEASON',
        /* ----------------------------- canEditBillingPage scopes ----------------------------- */
        'FP_CAN_EDIT_SELF_BILLING',
        'FP_CAN_EDIT_ALL_BILLING',
        /* ----------------------------- canMakePayment scopes ----------------------------- */
        'FP_CAN_MAKE_SELF_PAYMENT',
        'FP_CAN_MAKE_ALL_PAYMENT',
        /* ----------------------------- canEditIban scopes ----------------------------- */
        'FP_CAN_EDIT_SELF_IBAN',
        /* ---------------------- Results scope ---------------------- */
        'FP_CAN_SEE_ALL_RESULT',
        'FP_CAN_SEE_ASSIGNED-ASSOCIATE_RESULT',
        'FP_CAN_SEE_ASSIGNED-PARTNER_RESULT',
        'FP_CAN_SEE_SELF_RESULT',
        'FP_CAN_SEE_UNPUBLISHED_RESULT',
        /* ---------------------- Alarming data scope ---------------------- */
        'PP_CAN_SEE_ALARMING_DATA',
        'PP_CAN_SEE_ASSIGNED_ALARMING_DATA',
        'PP_CAN_SEE_PARTNER_ALARMING_DATA',
        'PP_CAN_SEE_ASSIGNED_PARTNER_ALARMING_DATA',
    ];
};
