import { IconPlant } from '@soil-capital/ui-kit/icons';
import { useCoverCropsGraphData } from './useCoverCropsGraph.data';
import { useCoverCropsGraphsStyles } from './CoverCropsGraph.style';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';

export const CoverCropsGraph = () => {
    const { t, coverCropsGraphRef, isLoading, hasNoData } = useCoverCropsGraphData();
    const { classes } = useCoverCropsGraphsStyles();
    return (
        <GraphContainer
            title={t('results.summary.sequestration.cover-crops.title')}
            titleIcon={<IconPlant />}
            isLoading={isLoading}
            hasNoData={hasNoData}
            orientation="vertical"
            className={classes.noPadding}
            hasBorders
        >
            <div ref={coverCropsGraphRef} className={classes.graphContainer}></div>
        </GraphContainer>
    );
};
