import { SplittedFieldModal } from '@modules/encoding/modules/rotation/components/SplittedFieldModal/SplittedFieldModal';
import { useSplit } from '@shared/map/hook/useSplit';
import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { IconSplit, IconCheckboxChecked, IconClose } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';

export const MapSplit = ({ readOnly }: { readOnly: boolean }) => {
    const { t } = useTranslation();

    const {
        split,
        stopSplitting,
        drawMode,
        editMode,
        splitMode,
        newPolygons,
        onFinisSplit,
        modalController,
        mergeMode,
        invalidFieldsNumber,
    } = useSplit();

    return (
        <>
            {splitMode ? (
                <>
                    <Button startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />} onClick={stopSplitting}>
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!newPolygons?.length}
                        variant="contained"
                        onClick={onFinisSplit}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : !drawMode && !editMode && !mergeMode ? (
                <Tooltip text={invalidFieldsNumber ? t('encoding-rotation.introduction.invalid-fields-tooltip') : ''}>
                    <Button
                        onClick={split}
                        startIcon={<IconSplit color="primary" />}
                        disabled={readOnly || !!invalidFieldsNumber}
                        sx={{ whiteSpace: 'nowrap' }}
                    >
                        {t('encoding-rotation.map.split-field')}
                    </Button>
                </Tooltip>
            ) : null}
            <SplittedFieldModal newPolygons={newPolygons} modalController={modalController} />
        </>
    );
};
