import { IconOrganicFertilisation } from '@soil-capital/ui-kit/icons';
import { useOrganicFertilisationGraphsStyles } from './OrganicFertilisationGraphs.style';
import { useOrganicFertilisationGraphsData } from './useOrganicFertilisationGraphs.data';
import { Table } from '@soil-capital/ui-kit/components';
import { GraphContainer } from '../../shared/GraphContainer/GraphContainer';
import { TableSkeleton } from '../../shared/TableSkeleton/TableSkeleton';

export const OrganicFertilisationGraphs = () => {
    const { productConsumptionGraphRef, t, fertiliserSlugs, formattedTableData, isLoading, hasNoData } =
        useOrganicFertilisationGraphsData();
    const { classes } = useOrganicFertilisationGraphsStyles();

    return (
        <GraphContainer
            title={t('results.summary.sequestration.organic-fertilisation-title')}
            titleIcon={<IconOrganicFertilisation />}
            orientation="vertical"
            hasNoData={hasNoData}
        >
            <div className={classes.flexbox}>
                <GraphContainer
                    title={t('results.summary.sequestration.organic-fertilisation.product-consumption')}
                    isLoading={isLoading}
                    className={classes.noPadding}
                    hasBorders
                >
                    <div ref={productConsumptionGraphRef} className={classes.graphContainer} />
                </GraphContainer>

                {isLoading ? (
                    <div className={classes.skeletonContainer}>
                        <TableSkeleton />
                    </div>
                ) : (
                    <div className={classes.tableContainer}>
                        <Table
                            headers={fertiliserSlugs}
                            data={formattedTableData}
                            seeLessLabel={t('constants.see-less')}
                            seeMoreLabel={t('constants.see-more')}
                            seeNextLabel={t('constants.see-next')}
                            stickyFirstColumn
                        />
                    </div>
                )}
            </div>
        </GraphContainer>
    );
};
