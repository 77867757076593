import { useTranslation, Trans } from 'react-i18next';
import { usePricingCardStyle } from '../PricingCard.style';
import { Card } from '@soil-capital/ui-kit/components';
import { IconStar } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { PricingCard } from '../PricingCard';
import { useNavigate } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { usePostCreateOrUpdateNextFarmSeasonAndProductPlanMutation } from '@modules/payment/api/paymentApi';
import { useEffect } from 'react';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import { auth } from '@modules/auth';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';

interface RenewalCardPropsT {
    discount: number | undefined;
    currency: string;
    price: number;
    subscriptionPrice: number;
    payableHectares: number;
    pricePerHectare: number;
    discountEndDate: string | undefined;
    isBelgian: boolean;
    isFloor: boolean;
    isCeiling: boolean;
    hasTriedToPay: boolean;
}

export const RenewalCard = ({
    discount,
    price,
    currency,
    subscriptionPrice,
    payableHectares,
    pricePerHectare,
    discountEndDate,
    isBelgian,
    isFloor,
    isCeiling,
    hasTriedToPay,
}: RenewalCardPropsT) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { classes } = usePricingCardStyle();
    const [track] = useTrackMutation();
    const authUser = auth.useAuthUser();
    const { currentSeasonId } = useCurrentSeasonId();
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const seasonId = activeSeason?.id ?? currentSeasonId;
    const [postMutation, { data, isLoading }] = usePostCreateOrUpdateNextFarmSeasonAndProductPlanMutation();
    const handleSubmit = async () => {
        if (discount) {
            track({
                event: EVENTS.RENEW_IN_ADVANCE_OPTION_SELECTED,
                properties: { user_role: authUser?.role?.name ?? '', payment_discount: `${discount}%` },
            });
        }
        await postMutation({
            farmSeasonId: seasonId,
            farmSeasonProductPlanName: 'HA_BASED',
        });
    };

    useEffect(() => {
        if (data) {
            navigate(`/${seasonId}/payment/billing-info`);
        }
    }, [data, navigate, seasonId]);

    return (
        <PricingCard
            title={t('pages.payment.pricing.pricing-card.title')}
            price={
                discount ? (
                    <>
                        <span className={classes.price}>
                            {t('formatted-price', { price: subscriptionPrice, currency })}
                        </span>
                        {` ${t('formatted-price', { price, currency })}`} {isBelgian && t('constants.tax-free')}
                    </>
                ) : (
                    <span>
                        {`${t('formatted-price', { price: subscriptionPrice, currency })} `}
                        {isBelgian && t('constants.tax-free')}
                    </span>
                )
            }
            tooltip={
                discount && !isCeiling && !isFloor
                    ? t('billing.subscription.timeline.price-tooltip.harvest-discount', {
                          payable_hectares: payableHectares,
                          subscription_price: subscriptionPrice,
                          discount,
                          paid_price: price,
                          currency,
                          price_per_hectare: pricePerHectare,
                      })
                    : discount && (isCeiling || isFloor)
                    ? t('billing.subscription.timeline.price-tooltip.harvest-discount.floor-ceiling', {
                          payable_hectares: payableHectares,
                          subscription_price: subscriptionPrice,
                          discount,
                          paid_price: price,
                          currency,
                          minOrMax: isFloor ? t('constants.minimum') : t('constants.maximum'),
                      })
                    : t('billing.subscription.timeline.price-tooltip.harvest', {
                          payable_hectares: payableHectares,
                          subscription_price: subscriptionPrice,
                          price_per_hectare: pricePerHectare,
                          currency,
                      })
            }
            cta={t('pages.payment.pricing.pricing-card.cta', { price, currency })}
            ctaAction={handleSubmit}
            isCtaLoading={isLoading}
            showcase
        >
            {discount && (
                <Card className={classes.greyContainer}>
                    <IconStar color="success" />
                    <Typography variant="body2">
                        <Trans
                            t={t}
                            i18nKey={
                                hasTriedToPay || discountEndDate === undefined
                                    ? 'pages.payment.pricing.pricing-card.product-plan-discount'
                                    : 'pages.payment.pricing.pricing-card.discount'
                            }
                            values={{
                                discount_end_date: discountEndDate,
                                discount,
                            }}
                        />
                    </Typography>
                </Card>
            )}
            <Typography variant="body2" className={classes.dark}>
                <Trans
                    i18nKey={
                        discount
                            ? 'pages.payment.pricing.pricing-card.description.discount'
                            : 'pages.payment.pricing.pricing-card.description'
                    }
                    values={{ currency, price: subscriptionPrice }}
                />
            </Typography>
        </PricingCard>
    );
};
