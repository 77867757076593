import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useResultsEarningContentPageStyle } from './ResultsEarningContentPage.style';
import { CarbonResultsGraph } from '../CarbonResultsGraph/CarbonResultsGraph';
import { ResultsCalculationDetail } from '../ResultsCalculationDetail/ResultsCalculationDetail';
import { AnimatedArrow } from './AnimatedArrow';
import { ResultsFaq } from '../ResultsFaq/ResultsFaq';

export const ResultsEarningContentPageTeaser = () => {
    const { t } = useTranslation();
    const { classes } = useResultsEarningContentPageStyle();
    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{t('results.earning.title')}</Typography>
            </div>
            <div className={classes.teaserContainer}>
                <div className={classes.teaserSubContainer}>
                    <AnimatedArrow />
                    <div className={classes.teaserTextContainer}>
                        <Typography variant="h3">{t('results.earning.results-pending.text-title')}</Typography>
                        <Typography variant="body2">{t('results.earning.results-pending.text-subtitle-1')}</Typography>
                        <Typography variant="body2">
                            <Trans t={t} i18nKey={'results.earning.results-pending.text-subtitle-2'} />
                        </Typography>
                    </div>
                </div>
                <div className={classes.resultsContainerBlured}>
                    <div className={classes.graphContainer}>
                        <CarbonResultsGraph value={0.5} year={0} yearLegend={t('constants.baseline')} />
                    </div>
                    <div className={classes.earningContainer}>
                        <Typography variant="caps" className={classes.earningHeader}>
                            {t('results.earning.carbon-footprint.calculation-detail.title')}
                        </Typography>
                        <div className={classes.detailedCalculation}>
                            <ResultsCalculationDetail
                                ghgBalance={10}
                                cropEmissions={10}
                                cropSequestrations={10}
                                livestockEmissions={10}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <ResultsFaq />
        </>
    );
};
