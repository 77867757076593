import { useGetChalkQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useChalkGraph = () => {
    const { t } = useTranslation();
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);

    const {
        data: chalkData,
        isLoading: isLoadingChalk,
        isFetching: isFetchingChalk,
    } = useGetChalkQuery({
        farmSeasonId: selectedSeasonId,
    });

    const formattedChalk = useMemo(() => {
        if (!chalkData) {
            return [];
        }
        return [
            {
                title: `${parseFloat(chalkData.total_quantity).toFixed(2)} ${t('constants.unit.tons')}`,
                subtitle: t('results.summary.emissions.chalk-subtitle'),
            },
        ];
    }, [chalkData, t]);

    const hasNoData = useMemo(
        () => Number(chalkData?.total_quantity) === 0 && !isLoadingChalk && !isFetchingChalk,
        [chalkData?.total_quantity, isFetchingChalk, isLoadingChalk],
    );

    return {
        hasNoData,
        chalk: formattedChalk,
        isLoadingChalk: isLoadingChalk || isFetchingChalk,
    };
};
