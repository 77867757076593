import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const resultsEarningSeasonSelectionSlice = createSlice({
    name: 'resultsEarningSeason',
    initialState: {
        selectedSeasonId: null as number | null,
    },
    reducers: {
        setResultsEarningSelectedSeasonId(state, action: PayloadAction<number | null>) {
            state.selectedSeasonId = action.payload;
        },
    },
});

export const { setResultsEarningSelectedSeasonId } = resultsEarningSeasonSelectionSlice.actions;
