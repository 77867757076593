import { Autocomplete, Checkbox, ConditionalTooltip, Input } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { OnboardingStepsModal } from '../../../components/OnboardingStepsModal/OnboardingStepsModal';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { useEligibilityFormLogic } from './useEligibilityFormLogic';
import { FARM } from './useEligibilityFormLogic';
import { FormProvider } from 'react-hook-form';
import { EligibilityFormSkeleton } from '@modules/onboarding/components/EligibilityFormSkeleton/EligibilityFormSkeleton';
import { StaticCountryT } from '@shared/entities';

export const EligibilityForm = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { countries, methods, selectedCountry, errors, onSubmit, isPostFormLoading, isLoading } =
        useEligibilityFormLogic();

    return (
        <OnboardingStepsModal
            stepProgress={0}
            ctaAction={onSubmit}
            ctaText={t('pages.onboarding.eligibility.cta')}
            isCTAloading={isPostFormLoading}
            hideBackButton
        >
            <FormProvider {...methods}>
                <form noValidate onSubmit={onSubmit}>
                    <Grid
                        paddingTop={theme.spacing(7)}
                        display="flex"
                        flexDirection="column"
                        gap={theme.spacing(3)}
                        paddingRight={theme.spacing(3)}
                        paddingLeft={theme.spacing(3)}
                    >
                        {isLoading && !selectedCountry ? (
                            <EligibilityFormSkeleton />
                        ) : (
                            <>
                                <Typography>{t('pages.onboarding.eligibility.description')}</Typography>
                                <Grid display="grid" gridTemplateColumns="1fr 1fr" gap={theme.spacing(3)}>
                                    <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                        <Input
                                            fullWidth
                                            label={t('pages.onboarding.eligibility.farm-name')}
                                            inputProps={{ ...methods.register(FARM.NAME) }}
                                            placeholder={t('pages.onboarding.eligibility.farm-name.placeholder')}
                                        />
                                        {errors[FARM.NAME] && (
                                            <Typography variant="subcaption" color="error">
                                                {errors[FARM.NAME]?.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                        <Grid display="flex" alignContent="center">
                                            <Typography variant="caption" sx={{ paddingRight: theme.spacing(1) }}>
                                                {t('pages.onboarding.eligibility.cultivated-area').toUpperCase()}
                                            </Typography>
                                            <ConditionalTooltip text={t('pages.onboarding.eligibility.area.tooltip')} />
                                        </Grid>
                                        <Input
                                            fullWidth
                                            inputProps={{ ...methods.register(FARM.AREA) }}
                                            suffix="ha"
                                            placeholder={t('pages.onboarding.eligibility.cultivated-area.placeholder')}
                                        />
                                        {errors[FARM.AREA] && (
                                            <Typography variant="subcaption" color="error">
                                                {errors[FARM.AREA]?.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid width="100%" gap={theme.spacing(1)} display="flex" flexDirection="column">
                                    <Input
                                        label={t('pages.onboarding.eligibility.street-number')}
                                        inputProps={{ ...methods.register(FARM.ADDRESS) }}
                                        placeholder={t('pages.onboarding.eligibility.street-number.placeholder')}
                                        fullWidth
                                    />
                                    {errors[FARM.ADDRESS] && (
                                        <Typography variant="subcaption" color="error">
                                            {errors[FARM.ADDRESS]?.message}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid display="grid" gap={theme.spacing(3)} gridTemplateColumns="1fr 1fr 1fr">
                                    <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                        <Input
                                            label={t('pages.onboarding.eligibility.city')}
                                            inputProps={{ ...methods.register(FARM.CITY) }}
                                            placeholder={t('pages.onboarding.eligibility.city.placeholder')}
                                        />
                                        {errors[FARM.CITY] && (
                                            <Typography variant="subcaption" color="error">
                                                {errors[FARM.CITY]?.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                        <Input
                                            label={t('pages.onboarding.eligibility.postal-code')}
                                            inputProps={{ ...methods.register(FARM.POSTALCODE) }}
                                            placeholder={t('pages.onboarding.eligibility.postal-code.placeholder')}
                                        />
                                        {errors[FARM.POSTALCODE] && (
                                            <Typography variant="subcaption" color="error">
                                                {errors[FARM.POSTALCODE]?.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                        <Typography variant="caption">
                                            {t('billing.subscription.billing-form.country').toUpperCase()}
                                        </Typography>
                                        <Autocomplete
                                            value={selectedCountry}
                                            noOptionsText={`${t('constants.select')}...`}
                                            onChange={(event, value: StaticCountryT | null) => {
                                                if (value) {
                                                    methods.setValue(FARM.COUNTRY, value.id);
                                                }
                                            }}
                                            blurOnSelect
                                            clearOnBlur
                                            options={countries}
                                            getOptionLabel={(country: StaticCountryT) => t(country.translation_slug)}
                                            placeholder={`${t('constants.select')}...`}
                                            isOptionEqualToValue={(option: StaticCountryT, value: StaticCountryT) => {
                                                return option?.id === value?.id;
                                            }}
                                            multiple={false}
                                        />
                                        {errors[FARM.COUNTRY] && (
                                            <Typography variant="subcaption" color="error">
                                                {errors[FARM.COUNTRY]?.message}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                                <Grid gap={theme.spacing(1)} display="flex" flexDirection="column">
                                    <Input
                                        label={t('pages.onboarding.eligibility.phone-number')}
                                        fullWidth
                                        inputProps={{ ...methods.register(FARM.PHONENUMBER) }}
                                        placeholder={t('pages.onboarding.eligibility.phone-number.placeholder')}
                                    />
                                    {errors[FARM.PHONENUMBER] && (
                                        <Typography variant="subcaption" color="error">
                                            {errors[FARM.PHONENUMBER]?.message}
                                        </Typography>
                                    )}
                                </Grid>
                                {/* <Grid textAlign="left">
                                    <Checkbox
                                        label={
                                            <Typography variant="body2">
                                                {t('pages.onboarding.eligibility.livestock')}
                                            </Typography>
                                        }
                                        sx={{ marginRight: theme.spacing(1) }}
                                        name="terms"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            methods.setValue(FARM.LIVESTOCK, !e.target.checked);
                                        }}
                                        checked={!methods.watch(FARM.LIVESTOCK)}
                                    />
                                </Grid> */}
                                <Grid textAlign="left">
                                    <Checkbox
                                        label={
                                            <Typography variant="body2">
                                                {t('pages.onboarding.eligibility.forest-permanent-pasture')}
                                            </Typography>
                                        }
                                        name="terms"
                                        sx={{ marginRight: theme.spacing(1) }}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            methods.setValue(FARM.PERMANENTPASTURES, e.target.checked);
                                        }}
                                        checked={methods.watch(FARM.PERMANENTPASTURES)}
                                    />
                                </Grid>
                            </>
                        )}
                    </Grid>
                </form>
            </FormProvider>
        </OnboardingStepsModal>
    );
};
