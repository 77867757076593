import authApi from '../api/authApi';

export type PermissionNameT =
    | 'canEditFarmSeason'
    | 'canFinalizeFarmSeason'
    | 'canEditBillingPage'
    | 'canEditIban'
    | 'canMakePayment'
    | 'canSeeAlarmingData';

/**
 * Check if the authenticated user has a permission, making abstraction of the scopes who are handled by the backend.\
 * -> if any scope exist + we access the app, it means the user has access to the resource.
 * @param permission: the group of DB Permissions (canEditFarmSeason = FP_CAN_EDIT_ALL_FARM-SEASON || FP_CAN_EDIT_SELF_FARM-SEASON || ...)
 */
export const usePermission = <K extends PermissionNameT>(permission: K): boolean => {
    const permissions = authApi.useGetPermissionsQuery().data?.permissions ?? [];

    if (permission === 'canEditFarmSeason') {
        return permissions.some(
            (p) =>
                p === 'FP_CAN_EDIT_ALL_FARM-SEASON' ||
                p === 'FP_CAN_EDIT_SELF_FARM-SEASON' ||
                p === 'FP_CAN_EDIT_ASSIGNED-ASSOCIATE_FARM-SEASON' ||
                p === 'FP_CAN_EDIT_ASSIGNED-PARTNER_FARM-SEASON',
        );
    }

    if (permission === 'canFinalizeFarmSeason') {
        return permissions.some(
            (p) =>
                p === 'FP_CAN_FINALIZE_ALL_FARM-SEASON' ||
                p === 'FP_CAN_FINALIZE_ASSIGNED-ASSOCIATE_FARM-SEASON' ||
                p === 'FP_CAN_FINALIZE_PARTNER_FARM-SEASON' ||
                p === 'FP_CAN_FINALIZE_ASSIGNED-PARTNER_FARM-SEASON',
        );
    }

    if (permission === 'canEditBillingPage') {
        return permissions.some((p) => p === 'FP_CAN_EDIT_SELF_BILLING' || p === 'FP_CAN_EDIT_ALL_BILLING');
    }

    if (permission === 'canEditIban') {
        return permissions.some((p) => p === 'FP_CAN_EDIT_SELF_IBAN');
    }

    if (permission === 'canMakePayment') {
        return permissions.some((p) => p === 'FP_CAN_MAKE_SELF_PAYMENT' || p === 'FP_CAN_MAKE_ALL_PAYMENT');
    }

    if (permission === 'canSeeAlarmingData') {
        return permissions.some(
            (p) =>
                p === 'PP_CAN_SEE_ALARMING_DATA' ||
                p === 'PP_CAN_SEE_ASSIGNED_ALARMING_DATA' ||
                p === 'PP_CAN_SEE_PARTNER_ALARMING_DATA' ||
                p === 'PP_CAN_SEE_ASSIGNED_PARTNER_ALARMING_DATA',
        );
    }

    return false;
};
