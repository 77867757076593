import { useGetLivestockDataQuery } from '@modules/results/api/summary.api';
import { SharedStateT } from '@shared/store';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useLivestockGraph = () => {
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const { t } = useTranslation();

    const {
        data: livestockData,
        isLoading: isLoadingLivestock,
        isFetching: isFetchingLivestock,
    } = useGetLivestockDataQuery({
        farmSeasonId: selectedSeasonId,
    });

    const formattedLivestock = useMemo(() => {
        if (!livestockData) {
            return [];
        }
        return livestockData?.map((el) => {
            return {
                title: `${el.quantity} ${t(el.translation_slug)}`,
                subtitle: t('results.summary.emissions.livestock-subtitle'),
            };
        });
    }, [livestockData, t]);

    const hasNoData = useMemo(() => formattedLivestock.length === 0, [formattedLivestock]);

    return {
        hasNoData,
        livestock: formattedLivestock,
        isLoadingLivestock: isLoadingLivestock || isFetchingLivestock,
    };
};
