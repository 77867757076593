import { Card, Quote } from '@soil-capital/ui-kit/components';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { CarbonResultsGraph } from '../CarbonResultsGraph/CarbonResultsGraph';
import { truncateDecimals } from '@shared/utils/truncateDecimals';
import { ResultsCalculationDetail } from '../ResultsCalculationDetail/ResultsCalculationDetail';
import { useTranslation } from 'react-i18next';
import { useResultsEarningContentPageStyle } from './ResultsEarningContentPage.style';
import { FarmSeasonResultsV3T } from '@modules/results/api/results.types';
import { ResultsFaq } from '../ResultsFaq/ResultsFaq';

type ResultsEarningContentPageT = {
    isAuditDone: boolean;
    isNetEmitter: boolean;
    results: FarmSeasonResultsV3T;
    harvestYear: number;
};

export const ResultsEarningContentPage = ({
    isAuditDone,
    isNetEmitter,
    results,
    harvestYear,
}: ResultsEarningContentPageT) => {
    const { t } = useTranslation();
    const { classes } = useResultsEarningContentPageStyle();
    return (
        <>
            <div className={classes.header}>
                <Typography variant="h1">{t('results.earning.title')}</Typography>
                {!isAuditDone && (
                    <Card className={classes.disclaimerContainer}>
                        <IconInfo color="success" />
                        <Typography variant="body2">{t('results.earning.disclaimer')}</Typography>
                    </Card>
                )}
            </div>
            <Quote
                author={{
                    avatar: '/assets/images/agro-max-avatar.jpeg',
                    name: 'Max,',
                    role: t('results.introduction.quote.author.role'),
                }}
            >
                <Typography sx={{ mb: 1 }} variant="h3">
                    {isNetEmitter
                        ? t('results.earning.quote.emission-title')
                        : t('results.earning.quote.sequestration-title')}
                </Typography>
                {t('results.earning.quote.content')}
            </Quote>
            <div className={classes.resultsContainer}>
                <div className={classes.graphContainer}>
                    <CarbonResultsGraph
                        value={results.ghg_balance_average}
                        year={harvestYear}
                        yearLegend={t('constants.baseline')}
                        tooltip={t(
                            isNetEmitter
                                ? 'results.earning.graph.tooltip.emission'
                                : 'results.earning.graph.tooltip.sequestration',
                            {
                                tonsPerHa: truncateDecimals(Math.abs(results.ghg_balance_average), 2),
                                tonsTotal: truncateDecimals(Math.abs(results.ghg_balance), 2),
                            },
                        )}
                    />
                </div>
                <div className={classes.earningContainer}>
                    <Typography variant="caps" className={classes.earningHeader}>
                        {t('results.earning.carbon-footprint.calculation-detail.title')}
                    </Typography>
                    <div className={classes.detailedCalculation}>
                        <ResultsCalculationDetail
                            ghgBalance={results.ghg_balance}
                            cropEmissions={results.crop_net_emissions}
                            cropSequestrations={results.crop_net_sequestrations}
                            livestockEmissions={results.livestock_emissions}
                        />
                    </div>
                </div>
            </div>
            <ResultsFaq />
        </>
    );
};
