import { IconArrowEmission, IconArrowSequestration, IconCow } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { truncateDecimals } from '@shared/utils/truncateDecimals';
import { useResultsCalculationDetailStyles } from './ResultsCalculationDetail.style';

export const ResultsCalculationDetail = ({
    ghgBalance,
    cropEmissions,
    cropSequestrations,
    livestockEmissions,
}: {
    ghgBalance: number;
    cropEmissions: number;
    cropSequestrations: number;
    livestockEmissions: number;
}) => {
    const { t } = useTranslation();
    const { classes } = useResultsCalculationDetailStyles();
    const isNetEmitter = ghgBalance > 0;

    const ghgBalanceSlug = isNetEmitter
        ? 'results.earning.carbon-footprint.calculation-detail.emission.total'
        : 'results.earning.carbon-footprint.calculation-detail.sequestration.total';

    const cropSequestrationSlug =
        cropSequestrations > 0
            ? 'results.earning.carbon-footprint.calculation-detail.sequestration.crop'
            : 'results.earning.carbon-footprint.calculation-detail.emission.minus-sequestration';

    return (
        <>
            {isNetEmitter ? <IconArrowEmission color="warning" /> : <IconArrowSequestration color="success" />}
            <Typography variant="h3">
                {t(ghgBalanceSlug, {
                    tons: truncateDecimals(Math.abs(ghgBalance), 2),
                })}
            </Typography>
            <Typography variant="h3">=</Typography>
            <Typography variant="body2" className={classes.calculDetail}>
                <IconArrowEmission className={classes.calculDetailIcon} />
                <span>
                    {t('results.earning.carbon-footprint.calculation-detail.emission.crop', {
                        tons: truncateDecimals(cropEmissions, 2),
                    })}
                </span>
            </Typography>
            {livestockEmissions > 0 && (
                <>
                    <Typography variant="h3">+</Typography>
                    <Typography variant="body2" className={classes.calculDetail}>
                        <IconCow className={classes.calculDetailIcon} />
                        <span>
                            {t('results.earning.carbon-footprint.calculation-detail.emission.livestock', {
                                tons: truncateDecimals(livestockEmissions, 2),
                            })}
                        </span>
                    </Typography>
                </>
            )}
            <Typography variant="h3">+</Typography>
            <Typography variant="body2" className={classes.calculDetail}>
                <IconArrowSequestration className={classes.calculDetailIcon} />
                <span>
                    {t(cropSequestrationSlug, {
                        tons: truncateDecimals(Math.abs(cropSequestrations), 2),
                    })}
                </span>
            </Typography>
        </>
    );
};
