import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';

export const useIsEncodingDone = (forcedFarmSeasonId?: number) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: forcedFarmSeasonId ?? currentSeasonId });

    return {
        isLoading: progressState.isLoading,
        isFetching: progressState.isFetching,
        isEncodingDone:
            progressState.list?.length === 0
                ? false
                : progressState.list
                      .filter((step) => step.is_visible)
                      ?.every((step) => step.progress_total === step.progress_done),
        isEncodingStarted: progressState?.getBySlug(encodingRoutesConfig.PREPARATION)?.status === 'done',
    };
};
