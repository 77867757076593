import { makeStyles } from '@soil-capital/ui-kit/style';

export const useResultsFaqStyle = makeStyles()((theme) => ({
    container: {
        padding: theme.spacing(3),
        background: theme.palette.darkScale[50],
        borderRadius: theme.shape.borderRadius,
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    description: {
        marginBottom: theme.spacing(4),
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),

        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    sectionSubtitle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    content: {
        whiteSpace: 'pre-wrap',
    },
    accordionHeader: {
        padding: 0,
        fontSize: theme.typography.h3.fontSize,
        '.MuiAccordionSummary-content': {
            margin: 0,
        },
    },
    accordion: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        boxShadow: '0px 0px 32px 8px #323F4514',
        borderRadius: theme.shape.borderRadiusM,
    },
    detailedCalculation: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(0.5),
        paddingBottom: theme.spacing(3),
    },
    accordionsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}));
