import authApi from '@modules/auth/api/authApi';
import { useGetHistoricalFarmSeasonsQuery } from '@modules/encoding/modules/history/api/historyApi';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { skipToken } from '@reduxjs/toolkit/query';
import entities from '@shared/entities';
import { useIsEncodingDone } from '@shared/hooks/useIsEncodingDone';

export const useCanSeeResults = (forcedFarmSeasonId?: number) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { data, isLoading: isLoadingPermissions } = authApi.useGetPermissionsQuery();
    const { currentFarm, currentFarmLoading } = useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const selectedSeason = seasonState.getById(forcedFarmSeasonId ?? currentSeasonId);
    const baselineFarmSeasonId =
        selectedSeason?.baseline_farm_season_id === null ? selectedSeason?.id : selectedSeason?.baseline_farm_season_id;
    const {
        data: historicalFarmSeasons,
        isLoading: isLoadingHistoricalFarmSeasons,
        isFetching: isFetchingHistoricalFarmSeasons,
    } = useGetHistoricalFarmSeasonsQuery(baselineFarmSeasonId ? { baselineFarmSeasonId } : skipToken);
    const isBaseline = seasonState.isLoading ? undefined : selectedSeason?.baseline_farm_season_id === null;
    const isHistoryFarmSeason = isLoadingHistoricalFarmSeasons
        ? undefined
        : !!historicalFarmSeasons?.find((fs) => fs.id === selectedSeason?.id);
    const isYoyFarmSeason = !isBaseline && !isHistoryFarmSeason;
    const {
        isEncodingDone,
        isLoading: isLoadingIsEncodingDone,
        isFetching: isFetchingIsEncodingDone,
    } = useIsEncodingDone(forcedFarmSeasonId);

    const isLoading =
        isLoadingPermissions ||
        isLoadingHistoricalFarmSeasons ||
        isLoadingIsEncodingDone ||
        currentFarmLoading ||
        seasonState.isLoading;

    const isFetching = seasonState.isFetching || isFetchingHistoricalFarmSeasons || isFetchingIsEncodingDone;

    const canSeeResults =
        data?.permissions?.includes('FP_CAN_SEE_ALL_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_ASSIGNED-ASSOCIATE_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_ASSIGNED-PARTNER_RESULT') ||
        data?.permissions?.includes('FP_CAN_SEE_SELF_RESULT');

    const canSeeUnpublishedResults = data?.permissions?.includes('FP_CAN_SEE_UNPUBLISHED_RESULT');

    // Results were computed - does not mean they are published
    const hasResults = selectedSeason?.has_results;

    // Results were published
    const areResultsValidated = !!selectedSeason?.has_results_published;

    // Two possible scenarios:
    // 1. Results are computed and published -> All user with permission to see results can access them
    // 2. Results are computed but not published -> Only user with permission to see unpublished results can access them
    const hasAccessToResults = canSeeResults && hasResults && (areResultsValidated || canSeeUnpublishedResults);

    return {
        canSeeResults: (canSeeResults && isEncodingDone) || isYoyFarmSeason,
        canSeeUnpublishedResults,
        hasResults,
        areResultsValidated,
        hasAccessToResults,
        isLoading,
        isFetching,
    };
};
