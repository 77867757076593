import { useGetOrganicFertiliserQuery, useGetOrganicFertiliserPerCropQuery } from '@modules/results/api/summary.api';
import { formatNumber } from '@modules/results/utils/formatNumber';
import { useGetSelectedHarvestYear } from '@modules/results/hooks/useGetSelectedHarvestYear';
import { stackedBarGraphBuilder } from '@modules/results/utils/stackedBarGraphBuilder';
import { organicFertilisersGraphTheme } from '@modules/results/utils/theme/organicFertilisersGraphTheme';
import { SharedStateT } from '@shared/store';
import * as echarts from 'echarts';
import { useRef, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const useOrganicFertilisationGraphsData = () => {
    const productConsumptionGraphRef = useRef(null);
    const selectedSeasonId = useSelector((state: SharedStateT) => state.resultsSummarySeason.selectedSeasonId);
    const {
        data: organicFertiliserData,
        isLoading: isLoadingOrganicFertiliser,
        isFetching: isFetchingOrganicFertiliser,
    } = useGetOrganicFertiliserQuery({
        farmSeasonId: selectedSeasonId,
    });
    const {
        data: organicFertiliserTableData,
        isLoading: isLoadingOrganicFertiliserPerCrop,
        isFetching: isFetchingOrganicFertiliserPerCrop,
    } = useGetOrganicFertiliserPerCropQuery({ farmSeasonId: selectedSeasonId });
    const { selectedHarvestYear, selectedHarvestYearLoading } = useGetSelectedHarvestYear();
    const { t } = useTranslation();

    useEffect(() => {
        if (organicFertiliserData && productConsumptionGraphRef.current) {
            const productConsumptionGraph = echarts.init(
                productConsumptionGraphRef.current,
                organicFertilisersGraphTheme,
                {
                    renderer: 'svg',
                },
            );
            const formattedData: Record<string, Record<string, number>> = {
                [t('constants.harvest-year', { harvestYear: selectedHarvestYear })]: organicFertiliserData.reduce(
                    (acc, item) => {
                        acc[t(item.slug)] = parseFloat(Number(item.value).toFixed(2));
                        return acc;
                    },
                    {} as Record<string, number>,
                ),
            };
            const options = stackedBarGraphBuilder({
                data: formattedData,
                yTitle: t('results.summary.sequestration.organic-fertilisation.product-consumption-y-axis-legend'),
                hasLegend: true,
                hasTooltip: true,
                tooltipTitle: t('results.summary.sequestration.organic-fertilisation.product-consumption-tooltip'),
                tooltipUnit: 'T',
            });
            options.grid.right = '70%';
            options.legend.left = '35%';
            options.grid.left = '10%';
            productConsumptionGraph.setOption(options);
            return () => {
                productConsumptionGraph.dispose();
            };
        }
    }, [organicFertiliserData, t, selectedHarvestYear]);

    const fertiliserSlugs = [
        t('constants.crop'),
        ...(organicFertiliserTableData?.organic_fertiliser_list.map((fertiliser) => t(fertiliser.slug)) || []),
        t('constants.total'),
    ];

    const cropFertiliserMap: Record<
        number,
        {
            fertilisers: Record<string, string>;
            total_quantity: number;
        }
    > = {};

    organicFertiliserTableData?.organic_fertiliser_per_crop.forEach((item) => {
        if (!cropFertiliserMap[item.crop_id]) {
            cropFertiliserMap[item.crop_id] = { fertilisers: {}, total_quantity: 0 };
        }
        cropFertiliserMap[item.crop_id].fertilisers[item.fertiliser_id] = `${formatNumber(
            parseFloat(item.quantity),
        )} t/${t('constants.hectare-unit')}`;
        cropFertiliserMap[item.crop_id].total_quantity += parseFloat(item.total_quantity);
    });

    const formattedTableData = Object.entries(cropFertiliserMap).map(([cropId, { fertilisers, total_quantity }]) => [
        t(organicFertiliserTableData?.crop_list.find((crop) => crop.id === parseInt(cropId))?.slug || ''),
        ...(organicFertiliserTableData?.organic_fertiliser_list.map(
            (fertiliser) => fertilisers[fertiliser.id] || '0',
        ) || []),
        `${formatNumber(total_quantity)} t`,
    ]);

    const hasNoData = useMemo(
        () =>
            (organicFertiliserData?.length === 0 || !organicFertiliserTableData) &&
            !isLoadingOrganicFertiliser &&
            !isFetchingOrganicFertiliser &&
            !isLoadingOrganicFertiliserPerCrop &&
            !isFetchingOrganicFertiliserPerCrop,
        [
            isFetchingOrganicFertiliser,
            isFetchingOrganicFertiliserPerCrop,
            isLoadingOrganicFertiliser,
            isLoadingOrganicFertiliserPerCrop,
            organicFertiliserData?.length,
            organicFertiliserTableData,
        ],
    );

    return {
        hasNoData,
        productConsumptionGraphRef,
        t,
        fertiliserSlugs,
        formattedTableData,
        isLoading:
            isLoadingOrganicFertiliserPerCrop ||
            isFetchingOrganicFertiliserPerCrop ||
            isLoadingOrganicFertiliser ||
            isFetchingOrganicFertiliser ||
            selectedHarvestYearLoading,
    };
};
