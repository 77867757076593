import { MapContext } from '../utils/MapProvider';
import { useCallback, useContext, useRef, useState } from 'react';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { lineString } from '@turf/helpers';
import { polygonCut } from '../utils/polygonSplit';
import area from '@turf/area';
import { FieldT } from '@shared/entities';
import { FieldPolygonT } from '@shared/entities/field/field.types';
import { useModalController } from '@shared/hooks/useModalController';

export type GroupedFieldPolygonT = {
    [originalFeatureId: number]: FieldPolygonT[][];
};

export type SplittedFieldSameOriginT = { originFieldId: number; fieldsPolygons: FieldPolygonT[] };

export const useSplit = () => {
    const mapContext = useContext(MapContext);
    const { map, drawMode, editMode, splitMode, setSplitMode, fields, mergeMode, invalidFields } = mapContext ?? {};
    const drawControlRef = useRef<null | MapboxDraw>(null);
    const [newPolygons, setNewPolygons] = useState<SplittedFieldSameOriginT[] | null>(null);
    const invalidFieldsNumber = invalidFields?.length;

    const modalController = useModalController<{ newPolygon: FieldPolygonT }>();

    const drawOptions = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
            line_string: true,
        },
    });

    const handleDrawSplitLine = useCallback(
        (e: { features: { id: string; geometry: { coordinates: number[][] } }[] }) => {
            const addSplittedPolygonLayer = (newPolygons: FieldPolygonT[]) => {
                if (map) {
                    map.addSource('splittedPolygonsLayer', {
                        type: 'geojson',
                        data: {
                            type: 'FeatureCollection',
                            features: newPolygons,
                        },
                    });

                    map.addLayer({
                        id: 'splittedPolygonsFillLayer',
                        type: 'fill',
                        source: 'splittedPolygonsLayer',
                        paint: {
                            'fill-color': '#FB9537',
                            'fill-opacity': 0.7,
                        },
                    });
                    map.addLayer({
                        id: 'splittedPolygonsLineLayer',
                        type: 'line',
                        source: 'splittedPolygonsLayer',
                        paint: {
                            'line-color': '#f37e13',
                            'line-width': 2,
                            'line-dasharray': [1.5, 1.5],
                        },
                    });
                }
            };
            const line = e.features[0];
            const lineId = line.id;
            const lineFeature = lineString(line.geometry.coordinates);
            const newPolygons = [] as FieldPolygonT[];

            fields?.forEach((field: FieldT) => {
                const polygonFeature = field?.polygon;
                const polycut = polygonCut(polygonFeature?.geometry, lineFeature.geometry);
                if (polycut.length) {
                    polycut.forEach((v: FieldPolygonT, i: number) => {
                        const calculatedArea = area(v);
                        const newPolygon = {
                            id: new Date().valueOf() + Math.floor(Math.random() * (1000 - i + 1) + i),
                            type: 'Feature',
                            properties: {
                                label: `${polygonFeature?.properties.label} ${i + 1}`,
                                area: calculatedArea / 10000,
                                area_source: 'drawn',
                                originalFeatureId: polygonFeature?.id,
                            },
                            geometry: v.geometry,
                        } as FieldPolygonT;
                        newPolygons.push(newPolygon);
                    });
                }
            });
            const newPolygonsByOriginId = sortNewPolygonsByOriginFeatureId(newPolygons);
            setNewPolygons(newPolygonsByOriginId);
            addSplittedPolygonLayer(newPolygons);
            if (map) map.getCanvas().style.cursor = '';
            if (drawControlRef.current) drawControlRef.current.delete(lineId);
        },
        [map, fields],
    );

    const sortNewPolygonsByOriginFeatureId = (polygons: FieldPolygonT[]) => {
        const groupedFeatures: { [key: number]: FieldPolygonT[] } = {};
        polygons.forEach((polygon) => {
            const originalFeatureId = polygon.properties.originalFeatureId;
            if (originalFeatureId) {
                if (!groupedFeatures[originalFeatureId]) {
                    groupedFeatures[originalFeatureId] = [];
                }
                groupedFeatures[originalFeatureId].push(polygon);
            }
        });
        return Object.entries(groupedFeatures).map(([originFieldId, fieldsPolygons]) => ({
            originFieldId: Number(originFieldId),
            fieldsPolygons,
        }));
    };

    const removeSplittedPolygonLayer = useCallback(() => {
        if (map) {
            if (map.getLayer('splittedPolygonsFillLayer')) map.removeLayer('splittedPolygonsFillLayer');
            if (map.getLayer('splittedPolygonsLineLayer')) map.removeLayer('splittedPolygonsLineLayer');
            if (map.getSource('splittedPolygonsLayer')) map.removeSource('splittedPolygonsLayer');
        }
    }, [map]);

    const split = () => {
        if (map) {
            setSplitMode?.(true);
            map.addControl(drawOptions);
            drawControlRef.current = drawOptions;
            drawOptions.changeMode('draw_line_string');
            map.getCanvas().style.cursor = 'url("/assets/images/pencil-02.svg") 0 22, crosshair';
            map.on('draw.create', handleDrawSplitLine);
        }
    };

    const onFinisSplit = async () => {
        if (newPolygons && newPolygons.length) {
            for (const fieldsWithSameOriginId of newPolygons) {
                for (const fieldWithSameOriginId of fieldsWithSameOriginId.fieldsPolygons) {
                    if (!(await modalController.open({ newPolygon: fieldWithSameOriginId }))) break;
                }
            }
        }
        stopSplitting();
    };

    const stopSplitting = useCallback(() => {
        if (map) {
            setSplitMode?.(false);
            if (drawControlRef.current) map.removeControl(drawControlRef.current);
            map.off('draw.create', handleDrawSplitLine);
            map.getCanvas().style.cursor = '';
            setNewPolygons([]);
            removeSplittedPolygonLayer();
        }
    }, [handleDrawSplitLine, map, removeSplittedPolygonLayer, setSplitMode]);

    return {
        split,
        stopSplitting,
        drawMode,
        editMode,
        splitMode,
        newPolygons,
        onFinisSplit,
        modalController,
        mergeMode,
        invalidFieldsNumber,
    };
};
